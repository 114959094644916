import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { RcFile } from 'antd/lib/upload';
import Skeleton from 'react-loading-skeleton';

import './index.less';

import IbTypography from '../../../components/common/IbTypography';
import IbButton from '../../../components/common/IbButton';
import IbIcon from '../../../components/common/IbIcon';
import { botApi, botEditionApi, botStageApi } from '../../../../apis';
import { BotContentFormat, BotStageModel, BotTrainingModelModel, SingleBotModel } from '../../../../../api';
import { inboxAlertsSelectorAdd } from '../../../recoil';
import { ALLOWED_IMPORT_BOT_FILE_TYPES, AlertTypes } from '../../../../constants';
import SettingsModal from '../../../../simple-bot/pages/bots/SimpleBotCard/SettingsModal';
import SbBotVersionsModal from '../../../../simple-bot/components/SbBotVersionsModal';
import { downloadNamedFile } from '../../../../utils/fileUtil';
import SbUpload from '../../../../simple-bot/components/common/SbUpload';
import IbWebChatButton from '../../../components/IbWebChatButton';
import { isInvalidCastError } from '../../../../utils/errorUtils';

import BotCards from './BotCards';

const MAIN_CLASS_NAME = 'ib-bot-page';
const TOP_BAR_CLASS_NAME = `${MAIN_CLASS_NAME}__top-bar`;
const TOP_BAR_TITLE_CLASS_NAME = `${TOP_BAR_CLASS_NAME}__title`;
const TOP_BAR_TITLE_LINK_CLASS_NAME = `${TOP_BAR_TITLE_CLASS_NAME}__link`;
const TOP_BAR_BUTTONS_CLASS_NAME = `${TOP_BAR_CLASS_NAME}__buttons`;
const TOP_BAR_BUTTONS_UPLOAD_CLASS_NAME = `${TOP_BAR_BUTTONS_CLASS_NAME}__upload`;
const TOP_BAR_MOBILE_CLASS_NAME = `${TOP_BAR_CLASS_NAME}_mobile`;
const TOP_BAR_MOBILE_TITLE_ROW_CLASS_NAME = `${TOP_BAR_MOBILE_CLASS_NAME}__title-row`;
const TOP_BAR_MOBILE_ACTIONS_ROW_CLASS_NAME = `${TOP_BAR_MOBILE_CLASS_NAME}__actions-row`;
const BACK_BUTTON_CLASS_NAME = `${TOP_BAR_MOBILE_TITLE_ROW_CLASS_NAME}__back-button`;
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;

const BotPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { push } = useHistory();
  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const [botLoading, setBotLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [bot, setBot] = useState<SingleBotModel>();
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [botVersionsModalVisible, setBotVersionsModalVisible] = useState(false);

  const [trainingModels, setTrainingModels] = useState<BotTrainingModelModel[]>([]);

  const botStage = bot?.originStage;
  const botCurrentEdition = bot?.originCurrentEdition;

  const loading = botLoading || dataLoading;

  const loadBotAsync = async () => {
    try {
      const botResponse = await botApi.getBot(id);
      setBot(botResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
  };

  const loadAdditionalDataAsync = async () => {
    try {
      const trainingModelsResponse = await botApi.getBotTrainingModels();
      setTrainingModels(trainingModelsResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
  };

  const loadAllDataAsync = async () => {
    if (!id) return;
    setDataLoading(true);
    try {
      await loadAdditionalDataAsync();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
    setDataLoading(false);
  };

  const loadAllData = () => {
    loadAllDataAsync().finally();
  };
  useEffect(loadAllData, []);

  const loadBotDataAsync = async () => {
    if (!id) return;
    setBotLoading(true);
    try {
      await loadBotAsync();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
    setBotLoading(false);
  };

  const loadBotData = () => {
    loadBotDataAsync().finally();
  };
  useEffect(loadBotData, [id]);

  const onDataChanged = async () => {
    await loadBotDataAsync();
  };

  const goToBotsList = () => {
    push('/inbox/bots/');
  };

  const onSettingsButtonClick = () => !loading && setSettingsModalVisible(true);
  const onSettingsModalClose = () => setSettingsModalVisible(false);

  const onShowBotVersions = () => setBotVersionsModalVisible(true);
  const onBotVersionsModalClose = () => setBotVersionsModalVisible(false);

  const onBotVersionsModalDraftStageAdded = async () => {
    await loadBotDataAsync();
    setBotVersionsModalVisible(false);
  };

  const onExport = (botStage?: BotStageModel) => async () => {
    if (!botStage) return;

    try {
      const response = await botEditionApi.exportBotEdition(
        botStage.currentEdition.botEditionId,
        BotContentFormat.Json,
        { responseType: 'blob' }
      );
      downloadNamedFile(response);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Bot export error'),
      });
    }
  };

  const onImportBotFileUpload = (botStage?: BotStageModel) => async (file: RcFile, base64Content: string) => {
    if (!botStage) return;

    try {
      await botStageApi.importBot(botStage.id, {
        botFile: {
          fileName: file.name,
          mimeType: file.type,
          content: base64Content,
        },
      });
    } catch (e) {
      const message = isInvalidCastError(e as Error)
        ? t('Uploaded file is not a bot description file')
        : t('Bot import error');

      addAlert({
        type: AlertTypes.ERROR,
        content: message,
      });
    }
  };

  const renderTopBar = () => {
    const actions = [
      {
        onSelect: onShowBotVersions,
        icon: '',
        text: t('Version history'),
      },
      {
        onSelect: () => {
          if (bot?.originStage) {
            onExport(bot?.originStage)();
          }
        },
        icon: '',
        text: t('Export'),
      },
      {
        onSelect: () => {},
        icon: '',
        text: (
          <SbUpload
            accept={ALLOWED_IMPORT_BOT_FILE_TYPES.join(',')}
            className={TOP_BAR_BUTTONS_UPLOAD_CLASS_NAME}
            onFileUpload={onImportBotFileUpload(bot?.originStage)}
          >
            {t('Import')}
          </SbUpload>
        ),
      },
    ];

    const renderBreadcrumbs = () => {
      return (
        <IbTypography>
          <h2>
            <span className={TOP_BAR_TITLE_LINK_CLASS_NAME} onClick={goToBotsList}>
              {t('Chat bots')}
            </span>
            <span>&nbsp;/&nbsp;</span>
            {!bot ? <Skeleton inline height={30} width={200} /> : <span>{bot.entry.name}</span>}
          </h2>
        </IbTypography>
      );
    };

    return (
      <div className={TOP_BAR_CLASS_NAME}>
        <div className={TOP_BAR_TITLE_CLASS_NAME}>{renderBreadcrumbs()}</div>
        <div className={TOP_BAR_BUTTONS_CLASS_NAME}>
          <IbButton
            actions={actions}
            icon={<IbIcon iconName="setting-two" />}
            type="secondary"
            onClick={onSettingsButtonClick}
          >
            {t('Settings')}
          </IbButton>
          <IbWebChatButton botName={bot?.entry.name} botStage={botStage} />
        </div>
      </div>
    );
  };

  const renderTopBarMobile = () => {
    const onGoBack = () => goToBotsList();

    return (
      <div className={TOP_BAR_MOBILE_CLASS_NAME}>
        <div className={TOP_BAR_MOBILE_TITLE_ROW_CLASS_NAME}>
          <IbButton
            className={BACK_BUTTON_CLASS_NAME}
            icon={<IbIcon iconName="arrow-left" />}
            type="icon"
            onClick={onGoBack}
          />
          <IbTypography>
            <h4>{bot?.entry.name}</h4>
          </IbTypography>
        </div>
        <div className={TOP_BAR_MOBILE_ACTIONS_ROW_CLASS_NAME}>
          <IbButton icon={<IbIcon iconName="setting-two" />} type="link" onClick={onSettingsButtonClick}>
            {t('Settings')}
          </IbButton>
          <IbWebChatButton botName={bot?.entry.name} botStage={botStage} />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={CONTENT_CLASS_NAME}>
        <BotCards bot={bot} onDataChanged={onDataChanged} />
      </div>
    );
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      {renderTopBar()}
      {renderTopBarMobile()}
      {renderContent()}
      {botStage && botCurrentEdition && (
        <SettingsModal
          botEdition={botCurrentEdition}
          botStageId={botStage?.id}
          trainingModels={trainingModels}
          visible={settingsModalVisible}
          onClose={onSettingsModalClose}
          onDataChanged={onDataChanged}
        />
      )}
      {bot?.entry.id && (
        <SbBotVersionsModal
          botId={bot.entry.id}
          visible={botVersionsModalVisible}
          onClose={onBotVersionsModalClose}
          onDraftStageAdded={onBotVersionsModalDraftStageAdded}
        />
      )}
    </div>
  );
};

export default BotPage;
