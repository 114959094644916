import {
  AgentApi,
  AgentStageApi,
  AgentStageAccountApi,
  CampaignApi,
  CampaignJobApi,
  CommandApi,
  PersonApi,
  GroupApi,
  ConversationApi,
  ActivityApi,
  ApplicationApi,
  BotVersionApi,
  ConfigurationApi,
  TestInstanceApi,
  BotTemplatesApi,
  DirectLineTranscriptApi,
  IntentEntryApi,
  IntentApi,
  GroupEntryApi,
  BotApi,
  BotStageApi,
  BotEditionApi,
  BotTemplateApi,
  ScenarioTemplateApi,
  Elma365MetadataApi,
  Elma365BpmTemplateApi,
  Elma365AppApi,
  FileStorageApi,
  IntentEditionApi,
  AnalyticsReportsApi,
  FeatureManagementApi,
  ConversationExportApi,
  DirectLineTokenApi,
  InboxRecordApi,
  InboxChatApi,
  InboxParticipantApi,
  InboxMessageApi,
  InboxAttachmentApi,
  InboxChannelApi,
  TagApi,
  InboxOperatorGroupApi,
  InboxContactApi,
} from '../api';
import {
  DataEntryKbApi,
  DraftKbApi,
  InstanceKbApi,
  KnowledgeBaseKbApi,
  KnowledgeSourceKbApi,
  ReportKbApi,
  ReportRowKbApi,
  StageKbApi,
  TemplateKbApi,
  VersionKbApi,
} from '../kb-api';
import { UserAuthApi, TenantAuthApi } from '../auth-api';

import { BASEPATH } from './constants';
import { getConfig } from './utils/configUtil';

export const personApi = new PersonApi(undefined, BASEPATH);
export const groupApi = new GroupApi(undefined, BASEPATH);
export const campaignApi = new CampaignApi(undefined, BASEPATH);
export const campaignJobApi = new CampaignJobApi(undefined, BASEPATH);
export const agentApi = new AgentApi(undefined, BASEPATH);
export const agentStageApi = new AgentStageApi(undefined, BASEPATH);
export const agentStageAccountApi = new AgentStageAccountApi(undefined, BASEPATH);
export const commandApi = new CommandApi(undefined, BASEPATH);
export const conversationApi = new ConversationApi(undefined, BASEPATH);
export const activityApi = new ActivityApi(undefined, BASEPATH);
export const applicationApi = new ApplicationApi(undefined, BASEPATH);
export const botVersionApi = new BotVersionApi(undefined, BASEPATH);
export const configurationApi = new ConfigurationApi(undefined, BASEPATH);
export const testInstanceApi = new TestInstanceApi(undefined, BASEPATH);
export const botTemplatesApi = new BotTemplatesApi(undefined, BASEPATH);
export const directLineTranscriptApi = new DirectLineTranscriptApi(undefined, BASEPATH);
export const intentEntryApi = new IntentEntryApi(undefined, BASEPATH);
export const intentApi = new IntentApi(undefined, BASEPATH);
export const groupEntryApi = new GroupEntryApi(undefined, BASEPATH);
export const botApi = new BotApi(undefined, BASEPATH);
export const botStageApi = new BotStageApi(undefined, BASEPATH);
export const botEditionApi = new BotEditionApi(undefined, BASEPATH);
export const botTemplateApi = new BotTemplateApi(undefined, BASEPATH);
export const scenarioTemplateApi = new ScenarioTemplateApi(undefined, BASEPATH);
export const elma365MetadataApi = new Elma365MetadataApi(undefined, BASEPATH);
export const elma365BpmTemplateApi = new Elma365BpmTemplateApi(undefined, BASEPATH);
export const elma365AppApi = new Elma365AppApi(undefined, BASEPATH);
export const fileStorageApi = new FileStorageApi(undefined, BASEPATH);
export const intentEditionApi = new IntentEditionApi(undefined, BASEPATH);
export const analyticsReportsApi = new AnalyticsReportsApi(undefined, BASEPATH);
export const featureManagementApi = new FeatureManagementApi(undefined, BASEPATH);
export const conversationExportApi = new ConversationExportApi(undefined, BASEPATH);
export const directLineTokenApi = new DirectLineTokenApi(undefined, BASEPATH);
export const inboxRecordApi = new InboxRecordApi(undefined, BASEPATH);
export const inboxChatApi = new InboxChatApi(undefined, BASEPATH);
export const inboxParticipantApi = new InboxParticipantApi(undefined, BASEPATH);
export const inboxMessageApi = new InboxMessageApi(undefined, BASEPATH);
export const inboxAttachmentApi = new InboxAttachmentApi(undefined, BASEPATH);
export const inboxChannelApi = new InboxChannelApi(undefined, BASEPATH);
export const tagApi = new TagApi(undefined, BASEPATH);
export const inboxOperatorGroupApi = new InboxOperatorGroupApi(undefined, BASEPATH);
export const inboxContactApi = new InboxContactApi(undefined, BASEPATH);

export let knowledgeBaseKbApi: KnowledgeBaseKbApi;
export let stageKbApi: StageKbApi;
export let versionKbApi: VersionKbApi;
export let dataEntryKbApi: DataEntryKbApi;
export let instanceKbApi: InstanceKbApi;
export let knowledgeSourceKbApi: KnowledgeSourceKbApi;
export let draftKbApi: DraftKbApi;
export let reportKbApi: ReportKbApi;
export let reportRowKbApi: ReportRowKbApi;
export let templateKbApi: TemplateKbApi;
export const initKnowledgeBaseServiceApi = async (): Promise<void> => {
  const config = await getConfig();
  const basePath = config?.knowledgeBaseService?.basePath;
  knowledgeBaseKbApi = new KnowledgeBaseKbApi(undefined, basePath);
  stageKbApi = new StageKbApi(undefined, basePath);
  versionKbApi = new VersionKbApi(undefined, basePath);
  dataEntryKbApi = new DataEntryKbApi(undefined, basePath);
  instanceKbApi = new InstanceKbApi(undefined, basePath);
  knowledgeSourceKbApi = new KnowledgeSourceKbApi(undefined, basePath);
  draftKbApi = new DraftKbApi(undefined, basePath);
  reportKbApi = new ReportKbApi(undefined, basePath);
  reportRowKbApi = new ReportRowKbApi(undefined, basePath);
  templateKbApi = new TemplateKbApi(undefined, basePath);
};

export let userAuthApi: UserAuthApi;
export let tenantAuthApi: TenantAuthApi;
export const initAuthServiceApi = async (): Promise<void> => {
  const config = await getConfig();
  const basePath = config?.authService?.basePath;
  userAuthApi = new UserAuthApi(undefined, basePath);
  tenantAuthApi = new TenantAuthApi(undefined, basePath);
};
