import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import './index.less';

import IbSocial, { IbSocialType } from '../../common/IbSocial';
import IbButton from '../../common/IbButton';
import IbTypography from '../../common/IbTypography';
import IbSwitch from '../../common/IbSwitch';
import IbIcon from '../../common/IbIcon';
import IbPopover, { POPOVER_MENU_CLASS_NAME } from '../../common/IbPopover';
import {
  AgentStageAccountStatus,
  InboxChannelModel,
  InboxChannelOperatorsRoutingType,
  InboxChannelRoutingBotAssignmentType,
  SetupStatus,
} from '../../../../../api';
import IbTag from '../../common/IbTag';
import { getChannelLink } from '../../../../utils/stringUtil';

const MAIN_CLASS_NAME = 'ib-channel-list-item';
const HEADER_CLASS_NAME = `${MAIN_CLASS_NAME}__header`;
const HEADER_ROW_CLASS_NAME = `${HEADER_CLASS_NAME}__row`;
const SETTINGS_CLASS_NAME = `${MAIN_CLASS_NAME}__settings`;
const SETTINGS_ROW_CLASS_NAME = `${SETTINGS_CLASS_NAME}__row`;
const LINK_CLASS_NAME = `${SETTINGS_ROW_CLASS_NAME}__link`;

export interface IIbChannelListItemProps {
  loading: boolean;
  channel: InboxChannelModel;
  onSwitch: () => Promise<void>;
  onDelete: () => void;
  onEdit: () => void;
  onConfigureRouting: () => void;
  onDiagnosticsSelect: () => void;
}

const IbChannelListItem: React.FC<IIbChannelListItemProps> = ({
  loading,
  channel,
  onSwitch,
  onDelete,
  onEdit,
  onConfigureRouting,
  onDiagnosticsSelect,
}) => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [switching, setSwitching] = useState(false);

  const status = channel.agentStageAccount.setupResult?.status || SetupStatus.Success;
  const enabled = channel.status === AgentStageAccountStatus.Enabled;
  const routingType = channel.routing?.operators?.routingType;
  const social = channel.channelId as IbSocialType;
  const channelName = channel.displayName;
  const errorMessage = channel.agentStageAccount?.setupResult?.errorMessage || undefined;
  const connectedBotName = channel.routing?.bot?.enabled ? channel.routing?.bot?.botEntry?.name : undefined;
  const botAssignmentType = channel.routing?.bot?.enabled ? channel.routing?.bot?.assignmentType : undefined;
  const defaultOperatorGroup = channel.routing?.operators?.defaultGroup;
  const additionalOperatorGroups = channel.routing?.operators?.additionalGroups;
  const link = getChannelLink(channel.agentStageAccount);

  const onPopoverVisibleChange = (visible?: boolean) => {
    setPopoverVisible(visible || false);
  };

  const onDeleteClick = () => {
    setPopoverVisible(false);
    onDelete();
  };

  const onEditClick = () => {
    setPopoverVisible(false);
    onEdit();
  };

  const onConfigureRoutingClick = () => {
    setPopoverVisible(false);
    onConfigureRouting();
  };

  const onDiagnosticsClick = () => {
    setPopoverVisible(false);
    onDiagnosticsSelect();
  };

  const onSwitchChange = async () => {
    setSwitching(true);
    await onSwitch();
    setSwitching(false);
  };

  const getStatusLabel = () => {
    if (switching) {
      return enabled ? t('Disconnecting') : t('Connecting');
    }

    if (enabled) {
      if (status === SetupStatus.Success) return t('Connected');
      if (status === SetupStatus.Error) return t('Error');
      if (status === SetupStatus.Pending) return t('Connecting');
    }

    return t('Disconnected');
  };

  const getStatusStyle = (): 'default' | 'success' | 'error' => {
    if (!switching && enabled) {
      if (status === SetupStatus.Error) {
        return 'error';
      }

      if (status === SetupStatus.Success) {
        return 'success';
      }
    }

    return 'default';
  };

  const getRoutingTypeLabel = (): string => {
    switch (routingType) {
      case InboxChannelOperatorsRoutingType.Auto:
        return t('Auto');
      case InboxChannelOperatorsRoutingType.Initiative:
        return t('Initiative');
      default:
        return '';
    }
  };

  const getBotAssignmentTypeLabel = (): string => {
    switch (botAssignmentType) {
      case InboxChannelRoutingBotAssignmentType.Always:
        return t('Always');
      case InboxChannelRoutingBotAssignmentType.Manually:
        return t('Manually');
      case InboxChannelRoutingBotAssignmentType.NonWorkTime:
        return t('Out of hours');
      default:
        return '';
    }
  };

  const getOperatorGroupNames = (): string => {
    if (!defaultOperatorGroup && !additionalOperatorGroups?.length) {
      return t('All operators');
    }

    const additionalGroupNames = (additionalOperatorGroups || []).map((g) => g?.name).join(', ');
    const defaultGroupName = defaultOperatorGroup ? `${defaultOperatorGroup.name} (${t('main')})` : undefined;

    return [defaultGroupName, additionalGroupNames].filter((groupName) => !!groupName).join(', ');
  };

  const renderSocial = () => <IbSocial social={social} />;
  const renderTitle = () => (
    <IbTypography>
      <IbTypography.Paragraph strong>{channelName}</IbTypography.Paragraph>
      <h4>{channelName}</h4>
    </IbTypography>
  );
  const renderStatus = () => (
    <IbTag
      content={getStatusLabel()}
      iconLeft={<IbIcon iconName="dot" />}
      style={getStatusStyle()}
      tooltipContent={errorMessage}
    />
  );
  const renderSwitcher = () => <IbSwitch checked={enabled} loading={switching} onChange={onSwitchChange} />;
  const renderContextMenu = () => (
    <IbPopover
      content={
        <ul className={POPOVER_MENU_CLASS_NAME}>
          <li>
            <div onClick={onEditClick}>
              <IbIcon iconName="setting-two" />
              <span>{t('Set up channel')}</span>
            </div>
          </li>
          <li>
            <div onClick={onConfigureRoutingClick}>
              <IbIcon iconName="play-cycle" />
              <span>{t('Configure routing')}</span>
            </div>
          </li>
          <li>
            <div onClick={onDiagnosticsClick}>
              <IbIcon iconName="file-code" />
              <span>{t('Diagnostics')}</span>
            </div>
          </li>
          <li>
            <div onClick={onDeleteClick}>
              <IbIcon iconName="delete" />
              <span>{t('Delete')}</span>
            </div>
          </li>
        </ul>
      }
      placement="bottomRight"
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={onPopoverVisibleChange}
    >
      <IbButton icon={<IbIcon iconName="more-one" />} type="icon" />
    </IbPopover>
  );

  return (
    <div className={MAIN_CLASS_NAME}>
      {loading ? (
        <>
          <div className={HEADER_CLASS_NAME}>
            <Skeleton circle height={40} width={40} />
            <Skeleton height={16} width={185} />
          </div>
          <div className={SETTINGS_CLASS_NAME}>
            <Skeleton height={16} />
            <Skeleton height={16} />
            <Skeleton height={16} />
          </div>
        </>
      ) : (
        <>
          <div className={HEADER_CLASS_NAME}>
            <div className={HEADER_ROW_CLASS_NAME}>
              {renderSocial()}
              {renderTitle()}
              {renderStatus()}
              {renderSwitcher()}
              {renderContextMenu()}
            </div>
            <div className={HEADER_ROW_CLASS_NAME}>
              {renderSwitcher()}
              {renderStatus()}
            </div>
          </div>

          <div className={SETTINGS_CLASS_NAME}>
            <div className={SETTINGS_ROW_CLASS_NAME}>
              <IbTypography.Paragraph disabled>{t('Routing')}</IbTypography.Paragraph>
              <IbTypography.Paragraph>{getRoutingTypeLabel()}</IbTypography.Paragraph>
            </div>
            <div className={SETTINGS_ROW_CLASS_NAME}>
              <IbTypography.Paragraph disabled>{t('Operators groups')}</IbTypography.Paragraph>
              <IbTypography.Paragraph>{getOperatorGroupNames()}</IbTypography.Paragraph>
            </div>
            {!!connectedBotName && (
              <>
                <div className={SETTINGS_ROW_CLASS_NAME}>
                  <IbTypography.Paragraph disabled>{t('Connected bot')}</IbTypography.Paragraph>
                  <IbTypography.Paragraph>{connectedBotName}</IbTypography.Paragraph>
                </div>
                <div className={SETTINGS_ROW_CLASS_NAME}>
                  <IbTypography.Paragraph disabled>{t('Bot assignment')}</IbTypography.Paragraph>
                  <IbTypography.Paragraph>{getBotAssignmentTypeLabel()}</IbTypography.Paragraph>
                </div>
              </>
            )}
            {!!link && (
              <div className={SETTINGS_ROW_CLASS_NAME}>
                <IbTypography.Paragraph disabled>{t('Channel link')}</IbTypography.Paragraph>
                <IbTypography.Paragraph>
                  <a className={LINK_CLASS_NAME} href={link} rel="noreferrer" target="_blank">
                    {link}
                  </a>
                </IbTypography.Paragraph>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default IbChannelListItem;
