import React from 'react';
import { Modal } from 'antd';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbIcon from '../IbIcon';
import IbTypography from '../IbTypography';
import IbSpin from '../IbSpin';

export enum IbModalPosition {
  Centered = 'Centered',
  Fixed = 'Fixed',
  FixedTall = 'FixedTall',
}

const POSITION_DEFAULT = IbModalPosition.Centered;
const VISIBLE_DEFAULT = false;
const LOADING_DEFAULT = false;
const TITLE_DEFAULT = undefined;
const FOOTER_DEFAULT = null;
const ON_CANCEL_DEFAULT = () => {};

const MAIN_CLASS_NAME = 'ib-modal';
const CENTERED_CLASS_NAME = `${MAIN_CLASS_NAME}_centered`;
const FIXED_CLASS_NAME = `${MAIN_CLASS_NAME}_fixed`;
const TALL_CLASS_NAME = `${MAIN_CLASS_NAME}_tall`;

export interface IIbModalProps {
  className?: string;
  position?: IbModalPosition;
  visible?: boolean;
  loading?: boolean;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  onCancel?: () => void;
}

const IbModal: React.FC<IIbModalProps> = ({
  children,
  className,
  position = POSITION_DEFAULT,
  visible = VISIBLE_DEFAULT,
  loading = LOADING_DEFAULT,
  title = TITLE_DEFAULT,
  footer = FOOTER_DEFAULT,
  onCancel = ON_CANCEL_DEFAULT,
}) => {
  visible = getDefaultIfUndefined(visible, VISIBLE_DEFAULT);
  loading = getDefaultIfUndefined(loading, LOADING_DEFAULT);
  title = getDefaultIfUndefined(title, TITLE_DEFAULT);
  onCancel = getDefaultIfUndefined(onCancel, ON_CANCEL_DEFAULT);

  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  switch (position) {
    case IbModalPosition.Centered:
      classes.push(CENTERED_CLASS_NAME);
      break;
    case IbModalPosition.Fixed:
      classes.push(FIXED_CLASS_NAME);
      break;
    case IbModalPosition.FixedTall:
      classes.push(FIXED_CLASS_NAME);
      classes.push(TALL_CLASS_NAME);
      break;
  }

  return (
    <Modal
      destroyOnClose
      closeIcon={
        <>
          <IbIcon iconName="close-small" />
          <IbIcon iconName="close" size={20} />
        </>
      }
      footer={footer}
      maskClosable={false}
      title={
        <>
          {loading && <IbSpin />}
          <IbTypography>
            <h4>{title}</h4>
          </IbTypography>
        </>
      }
      visible={visible}
      wrapClassName={classes.join(' ')}
      onCancel={onCancel}
    >
      {children}
    </Modal>
  );
};

export default IbModal;
