import React, { memo } from 'react';
import { useHistory } from 'react-router';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactMarkdown from 'react-markdown';
import remarkDirective from 'remark-directive';
import visit from 'unist-util-visit';
import hs from 'hastscript';

import './index.less';

import { InboxMentionModel, InboxMessageContentModel } from '../../../../../api';
import { TEXT_FORMAT_TYPES } from '../../../../constants';

const MAIN_CLASS_NAME = 'ib-message';
const INLINE_CLASS_NAME = `${MAIN_CLASS_NAME}_inline`;
const NOT_INLINE_CLASS_NAME = `${MAIN_CLASS_NAME}_not-inline`;

const mentionStyling = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (tree: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visit(tree, (node: any) => {
      if (node.type === 'textDirective' || node.type === 'leafDirective' || node.type === 'containerDirective') {
        if (node.name !== 'mention') return;

        const data = node.data || (node.data = {});
        const tagName = node.type === 'textDirective' ? 'span' : 'div';

        data.hName = tagName;
        data.hProperties = hs(tagName, node.attributes).properties;
      }
    });
  };
};

const processMentions = (
  content: InboxMessageContentModel,
  mentions: InboxMentionModel[],
  mentionLinksEnabled: boolean
) => {
  mentions = [...mentions];
  mentions.sort((a, b) => a.startPosition - b.startPosition);

  const tags = {
    plain: {
      opening: {
        noLink: '<span class="mention">',
        linkPart1: `<span class="mention"><a href="${window.location.origin}/inbox/operators?id=`,
        linkPart2: `"/>`,
      },
      closing: {
        noLink: '</span>',
        link: '</a></span>',
      },
    },
    markdown: {
      opening: {
        noLink: ':mention[',
        link: `:mention[[`,
      },
      closing: {
        noLink: ']{.mention}',
        linkPart1: `](${window.location.origin}/inbox/operators?id=`,
        linkPart2: `)]{.mention}`,
      },
    },
  };

  // NOTE: разница между положением упоминаний в исходной строке и в модифицированной строке,
  // увеличивается по мере вставки маркдаун-директив в места упоминаний пользователей.
  let diff = 0;

  let text = content.text;

  if (mentions.length) {
    for (const mention of mentions) {
      const openingTag = mentionLinksEnabled
        ? content.type === TEXT_FORMAT_TYPES.markdown
          ? tags.markdown.opening.link
          : `${tags.plain.opening.linkPart1}${mention.mentionedParticipantId}${tags.plain.opening.linkPart2}`
        : content.type === TEXT_FORMAT_TYPES.markdown
        ? tags.markdown.opening.noLink
        : tags.plain.opening.noLink;

      const closingTag = mentionLinksEnabled
        ? content.type === TEXT_FORMAT_TYPES.markdown
          ? `${tags.markdown.closing.linkPart1}${mention.mentionedParticipantId}${tags.markdown.closing.linkPart2}`
          : tags.plain.closing.link
        : content.type === TEXT_FORMAT_TYPES.markdown
        ? tags.markdown.closing.noLink
        : tags.plain.closing.noLink;

      text = `${text.slice(0, mention.startPosition + diff)}${openingTag}${text.slice(mention.startPosition + diff)}`;
      diff += openingTag.length;
      text = `${text.slice(0, mention.endPosition + diff)}${closingTag}${text.slice(mention.endPosition + diff)}`;
      diff += closingTag.length;
    }
  }

  return text;
};

export interface IIbMessageProps {
  inline: boolean;
  content: InboxMessageContentModel;
  mentions: InboxMentionModel[];
  mentionLinksEnabled: boolean;
}

const IbMessage: React.FC<IIbMessageProps> = ({ inline, content, mentions, mentionLinksEnabled }) => {
  const { push } = useHistory();
  const classes = [MAIN_CLASS_NAME, inline ? INLINE_CLASS_NAME : NOT_INLINE_CLASS_NAME];

  const onMessageClick = (e: React.MouseEvent) => {
    // NOTE: внутренние ссылки в сообщениях обрабатываются с помощью react-router,
    // чтобы не было перезагрузки всей страницы.
    if (e.target instanceof HTMLAnchorElement) {
      const url = new URL(e.target.href);

      if (url.origin === window.location.origin) {
        e.preventDefault();
        // NOTE: передается относительная часть адреса без origin.
        push(url.pathname + url.search);
      }
    }
  };

  return (
    <div className={classes.join(' ')} onClick={onMessageClick}>
      {content.type === TEXT_FORMAT_TYPES.markdown ? (
        <ReactMarkdown remarkPlugins={[remarkDirective, mentionStyling]}>
          {processMentions(content, mentions, mentionLinksEnabled)}
        </ReactMarkdown>
      ) : (
        <>
          {processMentions(content, mentions, mentionLinksEnabled)
            .split(/\r?\n/)
            .map((p, i) => (
              // eslint-disable-next-line react/no-danger
              <p key={i} dangerouslySetInnerHTML={{ __html: p }}></p>
            ))}
        </>
      )}
    </div>
  );
};

export default memo(IbMessage);
