import React, { useEffect, useState } from 'react';
import { Button, Menu, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import ReactWebChat, { createDirectLine } from 'botframework-webchat';
import { DrawerForm } from '@ant-design/pro-form';

import { AgentStageModel, HttpTransportType } from '../../../../api';
import { getProfile } from '../../../utils/oidcUtil';
import { webChatStyleOptions, webChatMarkdownRenderer } from '../../../constants';
import { getConfig } from '../../../utils/configUtil';

interface IWebChatDrawer {
  agentStage: AgentStageModel;
}

const WebChatDrawer: React.FC<IWebChatDrawer> = ({ agentStage }) => {
  const [initializing, setInitializing] = useState(true);
  const [webChatProps, setWebChatProps] = useState({});

  const initializeAsync = async () => {
    if (!agentStage.webChatUrl) return;
    setInitializing(true);

    // NOTE: для красивого рендеринга
    await (() => new Promise((res) => setTimeout(res, 200)))();

    const profile = getProfile();
    const config = await getConfig();
    const options = config?.botManagerService?.webChat;

    setWebChatProps({
      directLine: createDirectLine({
        domain: `/api/v1/directline/${agentStage.id}/testing`,
        token: profile.accessToken,
        webSocket: options.transport === HttpTransportType.WebSockets,
        pollingInterval: options.pollingInterval,
      }),
      userID: profile.userId,
      username: profile.userName,
      styleOptions: webChatStyleOptions,
      renderMarkdown: webChatMarkdownRenderer,
    });

    setInitializing(false);
  };
  const initialize = () => {
    initializeAsync().finally();
  };
  useEffect(initialize, []);

  const onRestartButtonClick = () => {
    initializeAsync().finally();
  };

  const onVisibleChange = (value: boolean) => {
    value && initializeAsync();
  };

  return (
    <DrawerForm
      drawerProps={{
        forceRender: true,
        destroyOnClose: true,
        footerStyle: { padding: 0 },
      }}
      submitter={{ render: () => [] }}
      title={
        <Button
          icon={<ReloadOutlined />}
          loading={initializing}
          style={{ padding: 0, height: 20 }}
          type="link"
          onClick={onRestartButtonClick}
        >
          Начать заново
        </Button>
      }
      trigger={<Menu.Item key="web-chat">в веб-чате</Menu.Item>}
      onVisibleChange={onVisibleChange}
    >
      {initializing ? <Spin className="web-chat-spinner" /> : <ReactWebChat {...webChatProps} />}
    </DrawerForm>
  );
};

export default WebChatDrawer;
