import React, { useEffect, useState } from 'react';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { Avatar, Button, Typography } from 'antd';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';

import SbTypography from '../../../../components/common/SbTypography';
import SbTooltip from '../../../../components/common/SbTooltip';
import SbButton from '../../../../components/common/SbButton';
import SbModal from '../../../../components/common/SbModal';
import SbPanel from '../../../../components/common/SbPanel';
import SbIcon from '../../../../components/common/SbIcon';
import { DirectLineIcon, Elma365Icon, LiveChatIcon, Logo, TelegramIcon, ViberIcon } from '../../../../../assets';
import { AlertTypes, Channels, DIALOGS_EXPORT_FINISHED } from '../../../../../constants';
import { renderMessageCount } from '../../../../../utils/stringUtil';
import { ConversationModel, ConversationStatus } from '../../../../../../api';
import { ChannelNames, Elma365ChannelIdentifiers } from '../../../../utils/dialogs';
import { conversationExportApi } from '../../../../../apis';
import { WhatsAppIcon } from '../../../../components/common/SbChannelIcon/icons';
import { hubConnections } from '../../../../../utils/socketsUtil';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';

const MAIN_CLASS_NAME = 'sb-dialogs-block__content';
const SESSION_DATE_FORMAT = 'DD.MM.YYYY в HH:mm';
const DATE_FORMAT = 'DD MMMM YYYY в HH:mm';
const DIALOG_INFO_ICON = {
  size: 40,
  innerSize: 20,
};
const CHANNEL_ICON_SIZE = 28;
const CHANNEL_ICON_PROPERTIES = {
  height: CHANNEL_ICON_SIZE,
  width: CHANNEL_ICON_SIZE,
  style: { marginTop: 6 },
};

interface IDialogExport {
  preparing: boolean;
  requestId: string;
  errorMessage: string;
  fileUrl: string;
}

const dialogExportDefaultValue: IDialogExport = {
  preparing: false,
  requestId: '',
  errorMessage: '',
  fileUrl: '',
};

interface IDialogInfoProps {
  conversation: ConversationModel;
  onRefresh: () => void;
}

const DialogInfo: React.FC<IDialogInfoProps> = ({ conversation, onRefresh }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const [dialogExport, setDialogExport] = useState(dialogExportDefaultValue);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const members = Object.values<{ nickName: string; id: string }>(conversation?.properties?.members ?? []);

  const closeExportReportModal = () => {
    setExportModalVisible(false);
    setDialogExport(dialogExportDefaultValue);
  };

  const runExportDialogsAsync = async () => {
    setDialogExport({
      ...dialogExportDefaultValue,
      preparing: true,
    });
    setExportModalVisible(true);

    try {
      const response = await conversationExportApi.runConversationExport(conversation.id);

      setDialogExport({
        ...dialogExportDefaultValue,
        requestId: response.data.requestId,
        preparing: true,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при запуске экспорта истории диалога',
        error: e,
      });
      closeExportReportModal();
    }
  };

  const dialogsExportEventHandler = (args: { requestId: string; fileUrl: string; errorMessage: string }) => {
    if (dialogExport.requestId !== args?.requestId || !dialogExport.preparing) {
      return;
    }

    const fileUrl = args?.fileUrl || '';
    setDialogExport({
      ...dialogExport,
      preparing: false,
      errorMessage: args?.errorMessage || '',
      fileUrl,
    });

    if (fileUrl) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(fileUrl, '_self');
    }
  };

  const subscribeToDialogsExportEvents = () => {
    if (!dialogExport.requestId || !conn) return;

    conn.on(DIALOGS_EXPORT_FINISHED, dialogsExportEventHandler);

    return () => {
      conn.off(DIALOGS_EXPORT_FINISHED, dialogsExportEventHandler);
    };
  };
  useEffect(subscribeToDialogsExportEvents, [conn, dialogExport.requestId]);

  const onChatDeskOpenButtonClick = () => {
    const serviceUrl = new URL(conversation.serviceUrl);
    const sessionId = conversation.properties.channelData.elma365.session.id;
    const chatDeskUrl = `${serviceUrl.origin}/_lines/_sessions/detail/${sessionId}`;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(chatDeskUrl, '_blank');
  };

  const getChannelIcon = () => {
    switch (conversation.channelId) {
      case Channels.TELEGRAM:
        return <TelegramIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.DIRECTLINE:
        return <DirectLineIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.VIBER:
        return <ViberIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.WHATSAPP:
        return <WhatsAppIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.ELMA365:
        return <Elma365Icon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.LIVECHAT:
        return <LiveChatIcon {...CHANNEL_ICON_PROPERTIES} />;
      default:
        return <Logo {...CHANNEL_ICON_PROPERTIES} />;
    }
  };

  const onCopySessionId = () => {
    copy(conversation.properties.channelData.elma365.session.id);
  };

  const onRefreshButtonClick = () => onRefresh();

  const onDialogExportButtonClick = () => runExportDialogsAsync().finally();

  const onExportModalClose = () => closeExportReportModal();

  return (
    <>
      <div className="sb-dialogs-card__content__panel-container__info__header">
        <Typography.Title ellipsis level={4}>
          Подробнее о беседе
        </Typography.Title>
        <SbButton icon={<SbIcon iconName="redo" />} sbSize="medium" sbType="icon-only" onClick={onRefreshButtonClick} />
      </div>
      <div className={MAIN_CLASS_NAME}>
        {conversation.status === ConversationStatus.Closed && (
          <SbPanel>Беседа закрыта {moment(conversation.finishedOn).format(SESSION_DATE_FORMAT)}</SbPanel>
        )}
        <div className={`${MAIN_CLASS_NAME}__info`}>
          <Avatar icon={getChannelIcon()} size={DIALOG_INFO_ICON.size} />
          <div>
            <div className={`${MAIN_CLASS_NAME}__info__channel-name`}>
              <div className={`${MAIN_CLASS_NAME}__info__channel-name__title`}>
                {ChannelNames.find((c) => c.value === conversation.channelId)?.label}
              </div>
              {conversation.channelId === Channels.ELMA365 &&
                conversation.properties?.channelData?.originalChannelId &&
                conversation.properties?.channelData?.elma365?.session?.id && (
                  <>
                    <div className={`${MAIN_CLASS_NAME}__info__channel-name__title`}>
                      ({Elma365ChannelIdentifiers[conversation.properties.channelData.originalChannelId]})
                    </div>
                    <SbTooltip
                      title={
                        <>
                          <span>{conversation.properties.channelData.elma365.session.id}</span>
                          <Button size="small" type="link" onClick={onCopySessionId}>
                            <SbIcon iconName="link-one" size={14} />
                          </Button>
                        </>
                      }
                    >
                      <SbIcon iconName="info" size={14} />
                    </SbTooltip>
                  </>
                )}
            </div>
            <div className={`${MAIN_CLASS_NAME}__info__title-optional`}>
              Создан {moment(conversation.startedOn).format(DATE_FORMAT)}
            </div>
          </div>
        </div>
        <div className={`${MAIN_CLASS_NAME}__info`}>
          <Avatar icon={<SbIcon iconName="comment" size={DIALOG_INFO_ICON.innerSize} />} size={DIALOG_INFO_ICON.size} />
          <div>
            <div className={`${MAIN_CLASS_NAME}__info__title-main`}>
              {renderMessageCount(conversation.messagesCount ?? 0)}
            </div>
            {conversation.messagesCount != 0 && (
              <div className={`${MAIN_CLASS_NAME}__info__title-optional`}>
                Последнее {moment(conversation.latestMessageOn).format(DATE_FORMAT)}
              </div>
            )}
          </div>
        </div>
        <div className={`${MAIN_CLASS_NAME}__info`}>
          <Avatar icon={<SbIcon iconName="user" size={DIALOG_INFO_ICON.innerSize} />} size={DIALOG_INFO_ICON.size} />
          <div>
            <div className={`${MAIN_CLASS_NAME}__info__title-main`}>{conversation.userName}</div>
            {!!members.length && (
              <div className={`${MAIN_CLASS_NAME}__info__title-optional`}>
                {conversation.channelId === Channels.TELEGRAM && members[0].nickName ? (
                  <a href={`https://t.me/${members[0].nickName}`} rel="noreferrer" target="_blank">
                    @{members[0].nickName}
                  </a>
                ) : (
                  (conversation.channelId === Channels.VIBER || conversation.channelId === Channels.TELEGRAM) &&
                  `User ID: ${members[0].id}`
                )}
              </div>
            )}
          </div>
        </div>
        <div className={`${MAIN_CLASS_NAME}__buttons`}>
          {conversation.channelId === Channels.ELMA365 && (
            <SbButton sbSize="big" sbType="secondary" onClick={onChatDeskOpenButtonClick}>
              Открыть сессию в ELMA365
            </SbButton>
          )}
          <SbButton sbSize="big" sbType="secondary" onClick={onDialogExportButtonClick}>
            Скачать транскрипт
          </SbButton>
        </div>
      </div>
      <SbModal
        footer={[
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onExportModalClose}>
            Закрыть
          </SbButton>,
        ]}
        sbSize="small"
        title="Экспорт истории диалога"
        visible={exportModalVisible}
        width={600}
        onCancel={onExportModalClose}
      >
        <SbTypography>
          {dialogExport.preparing ? (
            <SbTypography>
              <h4>Пожалуйста, подождите, происходит экспорт истории диалога...</h4>
            </SbTypography>
          ) : dialogExport.errorMessage ? (
            <SbPanel sbType="help">
              <SbTypography>
                <p>{dialogExport.errorMessage}</p>
              </SbTypography>
            </SbPanel>
          ) : (
            <SbTypography>
              <h4>История диалога успешно экспортирована!</h4>
              <a href={dialogExport.fileUrl}>Ссылка на скачивание</a>
            </SbTypography>
          )}
        </SbTypography>
      </SbModal>
    </>
  );
};

export default DialogInfo;
