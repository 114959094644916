import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { createDirectLine } from 'botframework-webchat';

import './index.less';

import { webChatMarkdownRenderer, WebChatMode } from '../../../constants';
import { directLineTokenApi } from '../../../apis';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import SbWebChatContent from '../../../simple-bot/components/SbWebChat/SbWebChatContent';
import { getConfig } from '../../../utils/configUtil';
import { HttpTransportType } from '../../../../api';

const webChatRenderDelay = 200; // мс
const botUriParamName = 'botUri';

const MAIN_CLASS_NAME = 'ib-webchat';
const FULLSCREEN_CLASS_NAME = `${MAIN_CLASS_NAME}_fullscreen`;
const EXPANDED_CLASS_NAME = `${MAIN_CLASS_NAME}_expanded`;
const HEADER_CLASS_NAME = `${MAIN_CLASS_NAME}__header`;
const HEADER_TITLE_CLASS_NAME = `${HEADER_CLASS_NAME}__title`;
const HEADER_SIZE_BUTTON_CLASS_NAME = `${HEADER_CLASS_NAME}__size-button`;
const HEADER_CLOSE_BUTTON_CLASS_NAME = `${HEADER_CLASS_NAME}__close-button`;

interface IIbWebChatProps {
  title: string;
  subTitle?: string;
  webchatUrl: string;
  onRestart?: () => void | Promise<void>;
  onClose?: () => void;
  mode?: WebChatMode;
}

const IbWebChat: React.FC<IIbWebChatProps> = ({
  title,
  subTitle,
  webchatUrl,
  onRestart = () => {},
  onClose = () => {},
  mode,
}) => {
  const urlParams = new URL(webchatUrl);
  const agentStageId = urlParams.searchParams.get(botUriParamName);

  const [initializing, setInitializing] = useState(true);
  const [webChatProps, setWebChatProps] = useState({});
  const [webChatMode, setWebChatMode] = useState(mode || WebChatMode.Default);

  const initializeAsync = async () => {
    if (!agentStageId) return;

    setInitializing(true);

    // NOTE: для красивого рендеринга
    await (() => new Promise((res) => setTimeout(res, webChatRenderDelay)))();

    let domain = `/api/v1/directline/${agentStageId}`;

    if (webChatMode !== WebChatMode.Fullscreen) {
      domain = `${domain}/testing`;
    }

    const {
      data: { conversationId, token, userId },
    } = await directLineTokenApi.generateDlToken();

    const config = await getConfig();
    const options = config?.botManagerService?.webChat;

    setWebChatProps({
      directLine: createDirectLine({
        domain,
        token,
        conversationId,
        webSocket: options.transport === HttpTransportType.WebSockets,
        pollingInterval: options.pollingInterval,
      }),
      userID: userId,
      username: 'web-chat-user',
      renderMarkdown: webChatMarkdownRenderer,
    });

    setInitializing(false);
  };
  const initialize = () => {
    initializeAsync().finally();
  };
  useEffect(initialize, [webchatUrl]);

  const onRestartButtonClick = async () => {
    await onRestart();
    await initializeAsync();
  };

  const onExpandButtonClick = () => {
    setWebChatMode(WebChatMode.Expanded);
  };

  const onFoldButtonClick = () => {
    setWebChatMode(WebChatMode.Default);
  };

  const classes = [MAIN_CLASS_NAME];

  switch (webChatMode) {
    case WebChatMode.Fullscreen:
      classes.push(FULLSCREEN_CLASS_NAME);
      break;
    case WebChatMode.Expanded:
      classes.push(EXPANDED_CLASS_NAME);
      break;
    case WebChatMode.Default:
    default:
      break;
  }

  return (
    <div className={classes.join(' ')}>
      <Row align="middle" className={HEADER_CLASS_NAME} wrap={false}>
        <Col className={HEADER_TITLE_CLASS_NAME} flex="auto">
          <h1>{title}</h1>
          {!!subTitle && <p>{subTitle}</p>}
        </Col>
        <Col>
          <IbButton
            icon={<IbIcon iconName="redo" size={20} />}
            loading={initializing}
            type="link"
            onClick={onRestartButtonClick}
          />
        </Col>
        <Col className={HEADER_SIZE_BUTTON_CLASS_NAME}>
          {webChatMode === WebChatMode.Expanded ? (
            <IbButton icon={<IbIcon iconName="click-to-fold" size={20} />} type="link" onClick={onFoldButtonClick} />
          ) : (
            <IbButton
              icon={<IbIcon iconName="expand-text-input" size={20} />}
              type="link"
              onClick={onExpandButtonClick}
            />
          )}
        </Col>
        <Col>
          <IbButton
            className={HEADER_CLOSE_BUTTON_CLASS_NAME}
            icon={<IbIcon iconName="close" size={20} />}
            type="link"
            onClick={onClose}
          />
        </Col>
      </Row>
      <SbWebChatContent initializing={initializing} webChatProps={webChatProps} />
    </div>
  );
};

export default IbWebChat;
