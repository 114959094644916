import MarkdownIt from 'markdown-it';

const md = MarkdownIt('default', { breaks: true }).disable('backticks');

export const webChatStyleOptions = {
  primaryFont: "'SF Pro Display'",
  bubbleBackground: '#f4f4f4',
  bubbleFromUserBackground: '#3062d6',
  bubbleFromUserTextColor: '#ffffff',
};

export const webChatMarkdownRenderer = md.render.bind(md);

export enum WebChatMode {
  Default = 'default',
  Fullscreen = 'fullscreen',
  Expanded = 'expanded',
}
