import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
  LogLevel,
} from '@microsoft/signalr';

import { NOTIFICATIONS_HUB_ENDPOINT } from '../constants';

import { getProfile } from './oidcUtil';
import { getConfig } from './configUtil';

interface IConnectionConfig {
  transport?: string;
  reconnectDelays?: number[];
  restartDelay?: number;
}

const DEFAULT_RESTART_DELAY = 60 * 1000;

const accessTokenFactory = (): string => {
  const profile = getProfile();
  return profile.accessToken;
};

const createHubConnection = (url: string, config?: IConnectionConfig) => {
  const options: IHttpConnectionOptions = {
    logger: LogLevel.Error,
    accessTokenFactory,
    transport: config?.transport
      ? HttpTransportType[config.transport as keyof typeof HttpTransportType]
      : HttpTransportType.WebSockets,
  };
  let builder = new HubConnectionBuilder().withUrl(url, options);
  builder = config?.reconnectDelays?.length
    ? builder.withAutomaticReconnect(config.reconnectDelays)
    : builder.withAutomaticReconnect();
  return builder.build();
};

class HubConnections {
  private readonly _startCallbacks: ((connection: HubConnection) => void)[];
  private readonly _errorCallbacks: ((connection: HubConnection, error: Error) => void)[];
  private readonly _closeCallbacks: ((connection: HubConnection, error?: Error) => void)[];
  private readonly _reconnectingCallbacks: ((connection: HubConnection, error?: Error) => void)[];
  private readonly _reconnectedCallbacks: ((connection: HubConnection, connectionId?: string) => void)[];

  private botManagerConnection?: HubConnection;
  private knowledgeBaseConnection?: HubConnection;

  public constructor() {
    this._startCallbacks = [];
    this._errorCallbacks = [];
    this._closeCallbacks = [];
    this._reconnectingCallbacks = [];
    this._reconnectedCallbacks = [];
  }

  public onStart(callback: (connection: HubConnection) => void) {
    this._startCallbacks.push(callback);
  }

  public onError(callback: (connection: HubConnection, error: Error) => void) {
    this._errorCallbacks.push(callback);
  }

  public onClose(callback: (connection: HubConnection, error?: Error) => void) {
    this._closeCallbacks.push(callback);
  }

  public onReconnecting(callback: (connection: HubConnection, error?: Error) => void) {
    this._reconnectingCallbacks.push(callback);
  }

  public onReconnected(callback: (connection: HubConnection, connectionId?: string) => void) {
    this._reconnectedCallbacks.push(callback);
  }

  public offStart(callback: (connection: HubConnection) => void) {
    const index = this._startCallbacks.indexOf(callback);
    if (index >= 0) {
      this._startCallbacks.splice(index, 1);
    }
  }

  public offError(callback: (connection: HubConnection, error: Error) => void) {
    const index = this._errorCallbacks.indexOf(callback);
    if (index >= 0) {
      this._errorCallbacks.splice(index, 1);
    }
  }

  public offClose(callback: (connection: HubConnection, error?: Error) => void) {
    const index = this._closeCallbacks.indexOf(callback);
    if (index >= 0) {
      this._closeCallbacks.splice(index, 1);
    }
  }

  public offReconnecting(callback: (connection: HubConnection, error?: Error) => void) {
    const index = this._reconnectingCallbacks.indexOf(callback);
    if (index >= 0) {
      this._reconnectingCallbacks.splice(index, 1);
    }
  }

  public offReconnected(callback: (connection: HubConnection, connectionId?: string) => void) {
    const index = this._reconnectedCallbacks.indexOf(callback);
    if (index >= 0) {
      this._reconnectedCallbacks.splice(index, 1);
    }
  }

  private initConnection(connection: HubConnection, config?: IConnectionConfig): void {
    connection.onclose(async (error) => {
      this._closeCallbacks.forEach((c) => c(connection, error));
      await this.startConnection(connection, config);
    });
    connection.onreconnecting((error) => this._reconnectingCallbacks.forEach((c) => c(connection, error)));
    connection.onreconnected((connectionId) => this._reconnectedCallbacks.forEach((c) => c(connection, connectionId)));
  }

  private async startConnection(connection: HubConnection, config?: IConnectionConfig): Promise<void> {
    try {
      await connection.start();
    } catch (e) {
      this._errorCallbacks.forEach((c) => c(connection, e));

      setTimeout(
        async () => await this.startConnection(connection, config),
        config?.restartDelay || DEFAULT_RESTART_DELAY
      );

      return;
    }

    this._startCallbacks.forEach((c) => c(connection));
  }

  public getBotManagerConnection = async (): Promise<HubConnection> => {
    if (this.botManagerConnection) {
      return this.botManagerConnection;
    }

    const allConfiguration = await getConfig();

    if (this.botManagerConnection) {
      return this.botManagerConnection;
    }

    const connectionConfig: IConnectionConfig | undefined = allConfiguration?.botManagerService?.signalR;
    const hubConnection = createHubConnection(NOTIFICATIONS_HUB_ENDPOINT, connectionConfig);
    this.botManagerConnection = hubConnection;
    this.initConnection(hubConnection, connectionConfig);
    await this.startConnection(hubConnection, connectionConfig);

    return this.botManagerConnection;
  };

  public getKnowledgeBaseConnection = async (): Promise<HubConnection> => {
    if (this.knowledgeBaseConnection) {
      return this.knowledgeBaseConnection;
    }

    const allConfiguration = await getConfig();

    if (this.knowledgeBaseConnection) {
      return this.knowledgeBaseConnection;
    }

    const basePath = allConfiguration?.knowledgeBaseService?.basePath;
    const connectionConfig: IConnectionConfig | undefined = allConfiguration?.knowledgeBaseService?.signalR;
    const hubConnection = createHubConnection(`${basePath}${NOTIFICATIONS_HUB_ENDPOINT}`, connectionConfig);
    this.knowledgeBaseConnection = hubConnection;
    this.initConnection(hubConnection, connectionConfig);
    await this.startConnection(hubConnection, connectionConfig);

    return this.knowledgeBaseConnection;
  };
}

export const hubConnections = new HubConnections();
