import { getConfig } from './configUtil';

export const tryAddLivechatStyles = async (): Promise<void> => {
  const config = await getConfig();
  const href = `${config?.livechat?.basePath}/index.css`;
  const existLink = document.querySelector(`[href="${href}"]`);

  if (existLink) {
    return;
  }

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  document.head.appendChild(link);
};
