import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValueLoadable, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ColumnType } from 'antd/lib/table';
import { RcFile } from 'antd/lib/upload';
import { Key } from 'ts-key-enum';
import { ExpandableConfig } from 'antd/lib/table/interface';

import './index.less';

import IbPageTitle from '../../../components/IbPageTitle';
import { botListFilterSelector, botListSelector, inboxAlertsSelectorAdd } from '../../../recoil';
import {
  AgentStageAccountStatus,
  BotContentFormat,
  BotSortDirection,
  BotStageModel,
  ListBotModel,
  ListBotModelPaginationResponse,
  UpdateBotOperationType,
} from '../../../../../api';
import { ALLOWED_IMPORT_BOT_FILE_TYPES, AlertTypes, RECOIL_LOADABLE_STATES } from '../../../../constants';
import IbTable, {
  MOBILE_MAIN_CELL_CLASS_NAME,
  MOBILE_ACTIONS_CELL_CLASS_NAME,
  IIbTableScrollProps,
} from '../../../components/common/IbTable';
import IbTypography from '../../../components/common/IbTypography';
import IbAvatar from '../../../components/common/IbAvatar';
import IbSocial, { IbSocialType } from '../../../components/common/IbSocial';
import { equalsIgnoreCase, formatDateTimeAdaptive, isWhiteSpace } from '../../../../utils/stringUtil';
import { botApi, botEditionApi, botStageApi } from '../../../../apis';
import { useQuery } from '../../../../utils/urlUtil';
import { webchatModalSelector } from '../../../../recoil/modals';
import IbButton from '../../../components/common/IbButton';
import IbIcon from '../../../components/common/IbIcon';
import IbContextMenu, { IIbContextMenuItem } from '../../../components/common/IbContextMenu';
import SbUpload from '../../../../simple-bot/components/common/SbUpload';
import { downloadNamedFile } from '../../../../utils/fileUtil';
import IbInput from '../../../components/common/IbInput';
import SbModal from '../../../../simple-bot/components/common/SbModal';
import CreateBotWizard from '../../../../simple-bot/pages/bots/CreateBotWizard';
import { BotsEmptyLogo } from '../../../assets';
import { isInvalidCastError } from '../../../../utils/errorUtils';
import IbTextHighlighter from '../../../components/common/IbTextHighlighter';
import { IIbTagsSearchData } from '../../../components/IbTagsSearch';

const MAIN_CLASS_NAME = 'ib-bots-page';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const EMPTY_CONTENT_CLASS_NAME = `${CONTENT_CLASS_NAME}-empty`;
const EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME = `${EMPTY_CONTENT_CLASS_NAME}__placeholder`;
const TABLE_CLASS_NAME = `${CONTENT_CLASS_NAME}__table`;
const NAME_CLASS_NAME = `${TABLE_CLASS_NAME}__name`;
const CHANNELS_CLASS_NAME = `${TABLE_CLASS_NAME}__channels`;
const ACTIONS_CLASS_NAME = `${TABLE_CLASS_NAME}__actions`;
const EXPANDED_ROW_ACTIONS_CLASS_NAME = `${TABLE_CLASS_NAME}__expanded-row-actions`;
const UPLOAD_CLASS_NAME = `${MAIN_CLASS_NAME}__upload`;
const CHANNEL_NAME_CLASS_NAME = `${CHANNELS_CLASS_NAME}__channel-name`;

const ACTIONS_DATA_INDEX = 'actions';
const CREATE_BOT_WIZARD_MODAL_WIDTH = 1364;
const SHOW_CREATE_BOT_MODAL_PARAM = 'showCreateBotModal';
const SHOW_CREATE_BOT_MODAL_PARAM_VALUE_TRUE = 'true';

const BotsPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { push } = useHistory();
  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);
  const query = useQuery();

  const setWebchatModalState = useSetRecoilState(webchatModalSelector);
  const resetWebchatModalState = useResetRecoilState(webchatModalSelector);

  const [botList, setBotList] = useState([] as ListBotModel[]);
  const [botListHasMore, setBotListHasMore] = useState(false);

  const [botListFilter, setBotListFilter] = useRecoilState(botListFilterSelector);
  const botListLoadable = useRecoilValueLoadable(botListSelector);

  const botListFilterIsSet = !!botListFilter.search;
  const botListIsLoading = botListLoadable.state === RECOIL_LOADABLE_STATES.loading && !botListFilterIsSet;
  const botListIsEmpty = !botListIsLoading && !botListLoadable.contents.items?.length && !botListFilterIsSet;

  const [preventNextRowClick, setPreventNextRowClick] = useState(false);
  const [editingTitle, setEditingTitle] = useState({ botId: '', botName: '' });
  const [openedMenuBotId, setOpenedMenuBotId] = useState<string>();

  const [isCreateBotWizardModalVisible, setIsCreateBotWizardModalVisible] = useState(
    equalsIgnoreCase(query.get(SHOW_CREATE_BOT_MODAL_PARAM) || '', SHOW_CREATE_BOT_MODAL_PARAM_VALUE_TRUE)
  );

  const onAdd = () => setIsCreateBotWizardModalVisible(true);

  const loadNextPage = () => {
    if (botListHasMore) {
      setBotListFilter({ ...botListFilter, pageIndex: botListFilter.pageIndex + 1, loadMore: true });
    }
  };

  const resetFilter = () =>
    setBotListFilter({ pageIndex: 0, loadMore: false, sort: BotSortDirection.StageModifiedOnDescending });

  const resetFilterPage = () => setBotListFilter({ ...botListFilter, pageIndex: 0, loadMore: false });

  const resetEditingTitle = () => setEditingTitle({ botId: '', botName: '' });

  const onDebouncedSearch = (value: IIbTagsSearchData) => {
    setBotListFilter({
      loadMore: false,
      pageIndex: 0,
      search: value.searchValue,
      sort: botListFilter.sort,
    });
  };

  // NOTE: закрытие вебчата при уходе со страницы
  useEffect(() => () => resetWebchatModalState(), [resetWebchatModalState]);

  const onInit = () => () => resetFilter();
  useEffect(onInit, []);

  const goToBot = (id: string) => {
    push(`/inbox/bots/${id}`);
  };

  const onTableRowMouseDown = () => {
    if (editingTitle.botId) {
      setPreventNextRowClick(true);
    }
  };

  const onTableRowClick = (bot: ListBotModel, column: ColumnType<unknown>) => {
    if (column.dataIndex === ACTIONS_DATA_INDEX || editingTitle.botId || preventNextRowClick) {
      setPreventNextRowClick(false);
      return;
    }

    goToBot(bot.entry.id);
  };

  const onSortDirectionChange = (value: string) => {
    setBotListFilter({ ...botListFilter, sort: value as BotSortDirection });
  };

  const onOpenMenu = (botId: string) => {
    setOpenedMenuBotId(botId);
  };

  const onCloseMenu = () => {
    setOpenedMenuBotId(undefined);
  };

  const onBotListLoadableStateChange = () => {
    if (botListLoadable.state !== RECOIL_LOADABLE_STATES.loading) {
      const data = botListLoadable.contents as ListBotModelPaginationResponse;
      setBotList(data.items || []);
      setBotListHasMore(!!data.hasMore);
    }
  };
  useEffect(onBotListLoadableStateChange, [botListLoadable.state]);

  const sortDirections = [
    {
      label: t('Recently modified first'),
      value: BotSortDirection.StageModifiedOnDescending,
    },
    {
      label: t('By name'),
      value: BotSortDirection.EntryNameAscending,
    },
  ];

  const getBotActions = (bot: ListBotModel) => {
    const onImportBotFileUpload = (botStage?: BotStageModel) => async (file: RcFile, base64Content: string) => {
      if (!botStage) return;

      try {
        await botStageApi.importBot(botStage.id, {
          botFile: {
            fileName: file.name,
            mimeType: file.type,
            content: base64Content,
          },
        });
        goToBot(bot.entry.id);
      } catch (e) {
        const message = isInvalidCastError(e as Error)
          ? t('Imported file is not a valid bot description file')
          : t('Bot import error');
        addAlert({
          type: AlertTypes.ERROR,
          content: message,
        });
      }
    };

    const onTestBot = async () => {
      try {
        const response = await botStageApi.testBotStage(bot.originStage.id);
        setWebchatModalState({ title: bot.entry.name, webchatUrl: response.data.webchatUrl });
      } catch (e) {
        addAlert({
          type: AlertTypes.ERROR,
          content: t('Bot testing error'),
        });
      }
    };

    const onExport = (botStage: BotStageModel) => async () => {
      try {
        const response = await botEditionApi.exportBotEdition(
          botStage.currentEdition.botEditionId,
          BotContentFormat.Json,
          { responseType: 'blob' }
        );
        downloadNamedFile(response);
      } catch (e) {
        addAlert({
          type: AlertTypes.ERROR,
          content: t('Bot export error'),
        });
      }
    };

    const onDuplicateBot = async () => {
      try {
        const newBot = await botApi.duplicateBot(bot.entry.id);
        goToBot(newBot.data.entry.id);
      } catch (e) {
        addAlert({
          type: AlertTypes.ERROR,
          content: t('Bot duplication error'),
        });
      }
    };

    const onEditTitle = () => {
      setEditingTitle({ botId: bot.entry.id, botName: bot.entry.name });
    };

    return [
      {
        text: t('Open'),
        onSelect: () => {
          goToBot(bot.entry.id);
        },
      },
      {
        text: t('Test'),
        onSelect: onTestBot,
      },
      {
        text: t('Rename'),
        onSelect: onEditTitle,
      },
      {
        text: t('Duplicate'),
        onSelect: onDuplicateBot,
      },
      {
        text: t('Export'),
        onSelect: onExport(bot.originStage),
      },
      {
        text: (
          <SbUpload
            accept={ALLOWED_IMPORT_BOT_FILE_TYPES.join(',')}
            className={UPLOAD_CLASS_NAME}
            onFileUpload={onImportBotFileUpload(bot.originStage)}
          >
            {t('Import')}
          </SbUpload>
        ),
      },
    ] as IIbContextMenuItem[];
  };

  const columns: ColumnType<ListBotModel>[] = [
    {
      className: MOBILE_MAIN_CELL_CLASS_NAME,
      title: t('Name'),
      render: (_, record) => {
        const onTitleInputChange = (value: string) => setEditingTitle({ botId: editingTitle.botId, botName: value });

        const onTitleInputBlur = async () => {
          const trimmedEditingTitle = editingTitle.botName.trim();
          resetEditingTitle();

          if (!editingTitle || trimmedEditingTitle === record.entry.name || isWhiteSpace(editingTitle.botName)) {
            return;
          }

          const newBots = botList.map((b) => {
            return b.entry.id === record.entry.id ? { ...b, entry: { ...b.entry, name: trimmedEditingTitle } } : b;
          });
          setBotList(newBots);

          try {
            await botApi.updateBot(record.entry.id, {
              operationType: UpdateBotOperationType.Rename,
              name: trimmedEditingTitle,
            });
          } catch (e) {
            addAlert({
              type: AlertTypes.ERROR,
              content: t('Bot updating error'),
            });
          }
          resetFilterPage();
        };

        const onTitleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === Key.Enter) {
            onTitleInputBlur().finally();
          } else if (e.key === Key.Escape) {
            resetEditingTitle();
          }
        };

        return (
          <div className={NAME_CLASS_NAME}>
            <IbAvatar metadata={{ uid: record.entry.id, text: record.entry.name }} size="x-small" />
            {editingTitle.botId === record.entry.id ? (
              <IbInput
                autoFocus
                value={editingTitle.botName}
                onBlur={onTitleInputBlur}
                onChange={onTitleInputChange}
                onKeyDown={onTitleInputKeyDown}
              />
            ) : (
              <IbTypography.Paragraph type="secondary">
                <IbTextHighlighter highlightedText={botListFilter.search} text={record.entry.name} />
              </IbTypography.Paragraph>
            )}
          </div>
        );
      },
    },
    {
      title: t('Scenarios'),
      render: (_, record) => (
        <div>
          <IbTypography.Paragraph disabled={!record.originStage.currentEdition.scenarios.length} type="secondary">
            {t('ScenarioWithCount', { count: record.originStage.currentEdition.scenarios.length })}
          </IbTypography.Paragraph>
        </div>
      ),
    },
    {
      title: t('Channels'),
      render: (_, record) => (
        <div className={CHANNELS_CLASS_NAME}>
          {record.entry.channels
            .filter((channel) => channel.status === AgentStageAccountStatus.Enabled)
            .map((channel) => (
              <div key={channel.agentStageAccountId} className={CHANNEL_NAME_CLASS_NAME}>
                <IbSocial social={channel.channelId as IbSocialType} />
                <IbTypography>
                  <IbTypography.Paragraph>{channel.displayName}</IbTypography.Paragraph>
                </IbTypography>
              </div>
            ))}
        </div>
      ),
    },
    {
      title: t('Modified'),
      render: (_, record) => (
        <div>
          <IbTypography>
            <IbTypography.Paragraph disabled type="secondary">
              {formatDateTimeAdaptive(record.originStage.modifiedOn, i18n.language, 'long', false)}
            </IbTypography.Paragraph>
          </IbTypography>
        </div>
      ),
    },
    {
      className: [ACTIONS_CLASS_NAME, MOBILE_ACTIONS_CELL_CLASS_NAME].join(' '),
      title: '',
      render: (_, record) => {
        const onMenuButtonClick = () => onOpenMenu(record.entry.id);
        return (
          <IbContextMenu
            menuItems={getBotActions(record)}
            visible={openedMenuBotId === record.entry.id}
            onClose={onCloseMenu}
          >
            <IbButton icon={<IbIcon iconName="more-one" />} type="icon" onClick={onMenuButtonClick} />
          </IbContextMenu>
        );
      },
      dataIndex: ACTIONS_DATA_INDEX,
    },
  ];

  const expandableForMobile: ExpandableConfig<ListBotModel> = {
    expandedRowRender: (record) => {
      const onOpenBotButtonClick = () => goToBot(record.entry.id);
      return (
        <>
          <ul>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Scenarios')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {t('ScenarioWithCount', { count: record.originStage.currentEdition.scenarios.length })}
              </IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Channels')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                <div className={CHANNELS_CLASS_NAME}>
                  {record.entry.channels
                    .filter((channel) => channel.status === AgentStageAccountStatus.Enabled)
                    .map((channel) => (
                      <div key={channel.agentStageAccountId} className={CHANNEL_NAME_CLASS_NAME}>
                        <IbSocial social={channel.channelId as IbSocialType} />
                        <IbTypography>
                          <IbTypography.Paragraph>{channel.displayName}</IbTypography.Paragraph>
                        </IbTypography>
                      </div>
                    ))}
                </div>
              </IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Modified')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {formatDateTimeAdaptive(record.originStage.modifiedOn, i18n.language, 'long', false)}
              </IbTypography.Paragraph>
            </li>
          </ul>
          <div className={EXPANDED_ROW_ACTIONS_CLASS_NAME}>
            <IbButton icon={<IbIcon iconName="edit" />} type="link" onClick={onOpenBotButtonClick}>
              {t('Open bot page')}
            </IbButton>
          </div>
        </>
      );
    },
  };

  const renderEmptyList = () => {
    return (
      <div className={EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME}>
        <BotsEmptyLogo />
        <IbTypography>
          <IbTypography.Paragraph disabled type="secondary">
            {t('Add new bots')}
            <br />
            {t('and connect them to the channels')}
            <br />
            {t('to process incoming messages')}
          </IbTypography.Paragraph>
        </IbTypography>
        <IbButton icon={<IbIcon iconName="add-one" />} onClick={onAdd}>
          {t('Add')}
        </IbButton>
      </div>
    );
  };

  const scroll = {
    dataLength: botList.length,
    hasMore: botListHasMore,
    next: loadNextPage,
  } as IIbTableScrollProps;

  const renderBotList = () => {
    const getBotRowKey = (bot: ListBotModel) => bot.entry.id;
    return (
      <IbTable
        className={TABLE_CLASS_NAME}
        columns={columns}
        dataSource={botList}
        emptyText={<IbTypography.Paragraph disabled>{t('Nothing found')}</IbTypography.Paragraph>}
        expandableForMobile={expandableForMobile}
        loading={botListIsLoading && !botList.length && !botListFilter.loadMore}
        rowKey={getBotRowKey}
        scroll={scroll}
        onRowClick={onTableRowClick}
        onRowMouseDown={onTableRowMouseDown}
      />
    );
  };

  const onModalCancel = () => setIsCreateBotWizardModalVisible(false);

  return (
    <div className={MAIN_CLASS_NAME}>
      <IbPageTitle
        selectedSortMode={botListFilter.sort}
        showSearchInput={!botListIsEmpty}
        sortModes={sortDirections}
        title={t('Chat bots')}
        onAdd={onAdd}
        onDebouncedSearch={onDebouncedSearch}
        onSortModeChanged={onSortDirectionChange}
      />
      <div className={`${CONTENT_CLASS_NAME} ${botListIsEmpty ? EMPTY_CONTENT_CLASS_NAME : ''}`}>
        {botListIsEmpty ? renderEmptyList() : renderBotList()}
      </div>
      <SbModal
        destroyOnClose
        footer={[]}
        sbSize="large"
        visible={isCreateBotWizardModalVisible}
        width={CREATE_BOT_WIZARD_MODAL_WIDTH}
        onCancel={onModalCancel}
      >
        <CreateBotWizard isInbox onClose={onModalCancel} />
      </SbModal>
    </div>
  );
};

export default BotsPage;
