import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import './index.less';

import SbModal from '../../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../../simple-bot/components/common/SbButton';
import { currentScenarioStructureSelector } from '../../../../../../recoil/scenarioStructure';
import SbScriptEditor from '../../../../../../simple-bot/components/common/SbScriptEditor';
import SbIcon from '../../../../../../simple-bot/components/common/SbIcon';
import { getConfig } from '../../../../../../utils/configUtil';

const MODAL_WIDTH_DEFAULT = '50vw';
const MODAL_WIDTH_EXPANDED = '100vw';

interface IScriptEditorProps {
  visible: boolean;
  readOnly?: boolean;
  content: string;
  onChange?: (content: string) => void;
  onClose: () => void;
}

const ScriptEditor: React.FC<IScriptEditorProps> = ({ visible, readOnly, content, onChange, onClose }) => {
  const scenarioStructure = useRecoilValue(currentScenarioStructureSelector);
  const [currentContent, setCurrentContent] = useState(content);
  const [width, setWidth] = useState(MODAL_WIDTH_DEFAULT);

  useEffect(() => {
    setCurrentContent(content);
  }, [visible, content]);

  const onModalOk = () => {
    if (onChange) {
      onChange(currentContent);
    }
    onClose();
  };

  const onModalCancel = () => onClose();

  const onSaveButtonClick = () => {
    if (onChange) {
      onChange(currentContent);
    }
    onClose();
  };

  const onCancelButtonClick = () => onClose();

  const onCurrentContentEdit = (value?: string) => setCurrentContent(value || '');

  const onDevDocsLinkButtonClick = async () => {
    const config = await getConfig();
    const basePath = config?.devDocs?.basePath;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(basePath, '_blank');
  };

  const onFoldButtonClick = () => {
    setWidth(MODAL_WIDTH_DEFAULT);
  };

  const onExpandButtonClick = () => {
    setWidth(MODAL_WIDTH_EXPANDED);
  };

  const renderHeader = () => (
    <>
      <div>{readOnly ? `Просмотр скрипта` : `Редактирование скрипта`}</div>
      <SbButton sbType="link" onClick={onDevDocsLinkButtonClick}>
        Документация разработчика
      </SbButton>
      {width === MODAL_WIDTH_EXPANDED ? (
        <SbButton icon={<SbIcon iconName="click-to-fold" size={20} />} sbType="link" onClick={onFoldButtonClick} />
      ) : (
        <SbButton
          icon={<SbIcon iconName="expand-text-input" size={20} />}
          sbType="link"
          onClick={onExpandButtonClick}
        />
      )}
    </>
  );

  return (
    <SbModal
      destroyOnClose
      className="script-editor-modal"
      footer={
        !readOnly && [
          <SbButton key="save" disabled={!currentContent} sbSize="medium" onClick={onSaveButtonClick}>
            Сохранить
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
            Отмена
          </SbButton>,
        ]
      }
      keyboard={false}
      maskClosable={false}
      sbSize="small"
      title={renderHeader()}
      visible={visible}
      width={width}
      onCancel={onModalCancel}
      onOk={onModalOk}
    >
      <div className="script-editor">
        <SbScriptEditor
          height="calc(100vh - 2 * 102px)"
          readOnly={readOnly}
          schema={scenarioStructure}
          value={currentContent}
          onChange={onCurrentContentEdit}
        />
      </div>
    </SbModal>
  );
};

export default ScriptEditor;
