import React, { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flags } from 'react-feature-flags';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Card, Divider, Input, Menu } from 'antd';
import moment from 'moment';
import numberToWordsRu from 'number-to-words-ru';
import { RcFile } from 'antd/lib/upload';
import { Key } from 'ts-key-enum';

import './index.less';

import SbContextMenu from '../common/SbContextMenu';
import { alertsSelectorAdd } from '../../../recoil/alerts';
import { botApi, botEditionApi, botStageApi } from '../../../apis';
import { BotContentFormat, BotStageModel, BotStageType, ListBotModel, UpdateBotOperationType } from '../../../../api';
import { AlertTypes, ALLOWED_IMPORT_BOT_FILE_TYPES, Channels, FeatureFlagNames } from '../../../constants';
import { downloadNamedFile } from '../../../utils/fileUtil';
import { webchatModalSelector } from '../../../recoil/modals';
import { isWhiteSpace } from '../../../utils/stringUtil';
import SbTabs from '../common/SbTabs';
import SbTabPane from '../common/SbTabPane';
import { currentBotStageTypeSelector } from '../../recoil';
import SbIcon from '../common/SbIcon';
import SbUpload from '../common/SbUpload';
import SbBotVersionsModal from '../SbBotVersionsModal';
import SbChannelIcon from '../common/SbChannelIcon';

const renderScenariosCount = (count: number) =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['сценарий', 'сценария', 'сценариев'],
      currencyNameDeclensions: {
        nominative: ['сценарий', 'сценарии'],
        genitive: ['сценария', 'сценариев'],
        dative: ['сценарию', 'сценариям'],
        accusative: ['сценарий', 'сценарии'],
        instrumental: ['сценарием', 'сценариями'],
        prepositional: ['сценарии', 'сценариях'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

const renderModificationDate = (modifiedOn: string) => {
  const dateFormat = 'DD.MM.YYYY в HH:mm';
  return `Изменен ${moment(modifiedOn).format(dateFormat)}`;
};

interface ISbBotListCardProps {
  bot: ListBotModel;
  onBotChanged: (bot: ListBotModel) => void;
}

const SbBotListCard: React.FC<ISbBotListCardProps> = ({ bot, onBotChanged }) => {
  const { push } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const setWebchatModalState = useSetRecoilState(webchatModalSelector);
  const [currentBotStageType, setCurrentBotStageType] = useRecoilState(currentBotStageTypeSelector(bot.entry.id));
  const [botVersionsModalVisible, setBotVersionsModalVisible] = useState(false);
  const [titleIsEditing, setTitleIsEditing] = useState(false);
  const [title, setTitle] = useState(bot.entry.name);

  const [draftCreating, setDraftCreating] = useState(false);
  const [draftDeleting, setDraftDeleting] = useState(false);

  const onOpenBot = (id?: string) => push(`/simple-bots/${id}`);
  const onOpenCurrentBot = () => onOpenBot(bot.entry.id);

  const onTestBot = async () => {
    try {
      const response = await botStageApi.testBotStage(bot.originStage.id);
      setWebchatModalState({ title: bot.entry.name, webchatUrl: response.data.webchatUrl });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при запуске тестирования бота',
        description: e instanceof Error ? e.message : String(e),
      });
    }
  };

  const onShowBotVersions = () => setBotVersionsModalVisible(true);
  const onBotVersionsModalClose = () => setBotVersionsModalVisible(false);

  const onTitleInputBlur = async () => {
    setTitleIsEditing(false);

    if (!title || title.trim() === bot.entry.name || isWhiteSpace(title)) {
      setTitle(bot.entry.name);
      return;
    }

    try {
      await botApi.updateBot(bot.entry.id, {
        operationType: UpdateBotOperationType.Rename,
        name: title.trim(),
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении названия бота',
        error: e,
      });
    }
  };

  const onTitleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => setTitle(e.target.value);

  const onTitleInputFocus: FocusEventHandler<HTMLInputElement> = (e) => e.target.select();

  const onTitleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === Key.Enter) {
      onTitleInputBlur().finally();
    } else if (e.key === Key.Escape) {
      setTitle(bot.entry.name);
      setTitleIsEditing(false);
    }
  };

  const onExport = (botStage?: BotStageModel) => async () => {
    if (!botStage) return;

    try {
      const response = await botEditionApi.exportBotEdition(
        botStage.currentEdition.botEditionId,
        BotContentFormat.Json,
        { responseType: 'blob' }
      );
      downloadNamedFile(response);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при экспорте бота',
        error: e,
      });
    }
  };

  const onCreateDraft = async () => {
    if (bot.draftStage) return;

    setDraftCreating(true);
    try {
      const draftCreationResponse = await botStageApi.createDraftBotStage(bot.originStage.id);
      onBotChanged({
        ...bot,
        draftStage: draftCreationResponse.data,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при создании черновика бота',
        error: e,
      });
    }
    setCurrentBotStageType(BotStageType.Draft);
    setDraftCreating(false);
  };

  const onDeleteDraft = async () => {
    if (!bot.draftStage) return;

    setDraftDeleting(true);
    try {
      await botStageApi.deleteBotStage(bot.draftStage.id);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении черновика бота',
        error: e,
      });
    }
    setCurrentBotStageType(BotStageType.Origin);
    onBotChanged({
      ...bot,
      draftStage: undefined,
    });
    setDraftDeleting(false);
  };

  const onDuplicateBot = async () => {
    try {
      const newBot = await botApi.duplicateBot(bot.entry.id);
      onOpenBot(newBot.data.entry.id);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при создании дубликата бота',
        error: e,
      });
    }
  };

  const onImportBotFileUpload = (botStage?: BotStageModel) => async (file: RcFile, base64Content: string) => {
    if (!botStage) return;

    try {
      await botStageApi.importBot(botStage.id, {
        botFile: {
          fileName: file.name,
          mimeType: file.type,
          content: base64Content,
        },
      });
      onOpenCurrentBot();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при импорте бота',
        error: e,
        description:
          e.response?.data?.title === 'InvalidCast' ? 'Загружаемый файл не является файлом описания бота' : undefined,
      });
    }
  };

  const onTabChange = (activeKey: string) => setCurrentBotStageType(activeKey as BotStageType);

  const onRenameBot = () => setTitleIsEditing(true);

  const cardClasses = ['sb-bot-list-card'];
  if (titleIsEditing) {
    cardClasses.push('sb-bot-list-card_unclickable');
  }

  const onBotVersionsModalDraftStageAdded = (draftStage: BotStageModel) =>
    onBotChanged({
      ...bot,
      draftStage,
    });

  const contextMenuContent =
    currentBotStageType === BotStageType.Draft ? (
      <Menu>
        <Menu.Item key="export" onClick={onExport(bot.draftStage)}>
          Экспортировать черновик
        </Menu.Item>
        <Menu.Item key="import-as-draft">
          <SbUpload
            accept={ALLOWED_IMPORT_BOT_FILE_TYPES.join(',')}
            className="sb-bot-list-card__import-upload"
            onFileUpload={onImportBotFileUpload(bot.draftStage)}
          >
            Импортировать в черновик
          </SbUpload>
        </Menu.Item>
        <Menu.Item key="delete" onClick={onDeleteDraft}>
          Удалить черновик
        </Menu.Item>
      </Menu>
    ) : (
      <Menu>
        <Menu.Item key="open" onClick={onOpenCurrentBot}>
          Перейти к сценариям
        </Menu.Item>
        <Menu.Item key="test" onClick={onTestBot}>
          Тестировать
        </Menu.Item>
        <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
          <Menu.Item key="connect">Подключить мессенжер</Menu.Item>
        </Flags>
        <Menu.Item key="rename" onClick={onRenameBot}>
          Переименовать
        </Menu.Item>
        <Menu.Item key="duplicate" onClick={onDuplicateBot}>
          Дублировать
        </Menu.Item>
        <Menu.Item key="create-draft" disabled={!!bot.draftStage} onClick={onCreateDraft}>
          Создать черновик
        </Menu.Item>
        <Menu.Item key="history" onClick={onShowBotVersions}>
          История версий
        </Menu.Item>
        <Menu.Item key="export" onClick={onExport(bot.originStage)}>
          Экспортировать
        </Menu.Item>
        <Menu.Item key="import-as-origin">
          <SbUpload
            accept={ALLOWED_IMPORT_BOT_FILE_TYPES.join(',')}
            className="sb-bot-list-card__import-upload"
            onFileUpload={onImportBotFileUpload(bot.originStage)}
          >
            Импортировать
          </SbUpload>
        </Menu.Item>
        <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
          <Menu.Item key="delete">Удалить</Menu.Item>
        </Flags>
      </Menu>
    );

  const renderStageContent = (botStage: BotStageModel) => (
    <div className="sb-bot-list-card__content">
      <div className="sb-bot-list-card__scenario-count">
        {renderScenariosCount(botStage.currentEdition.scenarios.length)}
      </div>
      <Divider className="sb-bot-list-card__divider" type="vertical" />
      <div className="sb-bot-list-card__scenario-date">{renderModificationDate(botStage.modifiedOn)}</div>
    </div>
  );

  const renderChannels = () => {
    const uniqueChannelTypes = Array.from(new Set(bot.entry.channels.map((c) => c.channelId)));

    return (
      <div className="sb-bot-list-card__channels">
        {uniqueChannelTypes.map((type) => (
          <SbChannelIcon key={type} size="normal" type={type as Channels} />
        ))}
      </div>
    );
  };

  return (
    <>
      <Card hoverable className={cardClasses.join(' ')} onClick={onOpenCurrentBot}>
        {draftDeleting || draftCreating ? (
          <SbIcon spin iconName="loading-four" />
        ) : (
          <>
            <div className="sb-bot-list-card__container">
              <div className="sb-bot-list-card__top">
                <h3 className="sb-bot-list-card__top__title">
                  {titleIsEditing ? (
                    <Input
                      autoFocus
                      value={title}
                      onBlur={onTitleInputBlur}
                      onChange={onTitleInputChange}
                      onClick={(e) => e.stopPropagation()}
                      onFocus={onTitleInputFocus}
                      onKeyDown={onTitleInputKeyDown}
                    />
                  ) : (
                    <span className="sb-bot-list-card__top__title-text">{title}</span>
                  )}
                </h3>
                <div className="sb-bot-list-card__top__icon" role="none" onClick={(e) => e.stopPropagation()}>
                  <SbContextMenu menuContent={contextMenuContent} />
                </div>
              </div>
              {bot.draftStage ? (
                <SbTabs activeKey={currentBotStageType} onChange={onTabChange}>
                  <SbTabPane key={BotStageType.Origin} tab="Основной">
                    {renderStageContent(bot.originStage)}
                  </SbTabPane>
                  <SbTabPane key={BotStageType.Draft} tab="Черновик">
                    {renderStageContent(bot.draftStage)}
                  </SbTabPane>
                </SbTabs>
              ) : (
                renderStageContent(bot.originStage)
              )}
            </div>
            <Flags authorizedFlags={[FeatureFlagNames.SIMPLE_BOT_CHANNELS_MANAGEMENT]}>
              {bot.entry.channels.length > 0 && renderChannels()}
            </Flags>
            <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
              <div className="sb-bot-list-card__state sb-bot-list-card__state_on">Подключен</div>
            </Flags>
          </>
        )}
      </Card>
      <SbBotVersionsModal
        botId={bot.entry.id}
        visible={botVersionsModalVisible}
        onClose={onBotVersionsModalClose}
        onDraftStageAdded={onBotVersionsModalDraftStageAdded}
      />
    </>
  );
};

export default SbBotListCard;
