import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Flags } from 'react-feature-flags';
import { Col, Divider, Menu, Row, Spin, Typography } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';

import './index.less';

import {
  BotContentFormat,
  BotStageModel,
  BotStageType,
  BotTrainingModelModel,
  ConversationModel,
  ConversationSortDirection,
  ConversationsPerDayModel,
  ConversationStatus,
  SingleBotModel,
} from '../../../../../api';
import { AlertTypes, ALLOWED_IMPORT_BOT_FILE_TYPES, FeatureFlagNames } from '../../../../constants';
import {
  agentApi,
  analyticsReportsApi,
  botApi,
  botEditionApi,
  botStageApi,
  conversationApi,
  knowledgeBaseKbApi,
} from '../../../../apis';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import SbButton from '../../../components/common/SbButton';
import SbIcon from '../../../components/common/SbIcon';
import SbUpload from '../../../components/common/SbUpload';
import { currentBotStageTypeSelector } from '../../../recoil';
import { SingleKnowledgeBaseModel } from '../../../../../kb-api';
import { downloadNamedFile } from '../../../../utils/fileUtil';
import SbBotVersionsModal from '../../../components/SbBotVersionsModal';
import SbContextMenu from '../../../components/common/SbContextMenu';
import { ANALYTICS_DATE_FORMATS } from '../../../const';

import ScenarioListBlock from './ScenarioListBlock';
import WebchatButton from './WebchatButton';
import KnowledgeBaseBlock from './KnowledgeBaseBlock';
import SettingsModal from './SettingsModal';
import AnalyticsBlock from './AnalyticsBlock';
import ChannelsBlock from './ChannelsBlock';
import DialogsBlock from './DialogsBlock';

const MAIN_BLOCK_CLASS_NAME = 'sb-bot-card__content__block';
const INNER_BLOCK_CLASS_NAME = `${MAIN_BLOCK_CLASS_NAME}__inner-wrapper`;
const INNER_HOVERABLE_BLOCK_CLASS_NAME = `${INNER_BLOCK_CLASS_NAME}_hoverable`;

const { Title } = Typography;

interface IDialogsBlockData {
  totalCount: number;
  activeCount: number;
  userCount: number;
  latestConversation?: ConversationModel;
}

interface IAnalyticsBlockData {
  conversationsPerDayList: ConversationsPerDayModel[];
}

const SimpleBotCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [currentBotStageType, setCurrentBotStageType] = useRecoilState(currentBotStageTypeSelector(id));

  const [loading, setLoading] = useState(false);
  const [bot, setBot] = useState<SingleBotModel>();
  const [knowledgeBase, setKnowledgeBase] = useState<SingleKnowledgeBaseModel>();

  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [botVersionsModalVisible, setBotVersionsModalVisible] = useState(false);

  const [dialogsBlockData, setDialogsBlockData] = useState<IDialogsBlockData>({
    totalCount: 0,
    activeCount: 0,
    userCount: 0,
    latestConversation: undefined,
  });
  const [analyticsBlockData, setAnalyticsBlockData] = useState<IAnalyticsBlockData>({
    conversationsPerDayList: [],
  });
  const [trainingModels, setTrainingModels] = useState<BotTrainingModelModel[]>([]);

  const [channelsClickable, setChannelsClickable] = useState(false);

  const isDraft = currentBotStageType === BotStageType.Draft;
  const botStage = isDraft ? bot?.draftStage : bot?.originStage;
  const botCurrentEdition = isDraft ? bot?.draftCurrentEdition : bot?.originCurrentEdition;

  const loadKnowledgeBaseData = async (knowledgeBaseId: string) => {
    try {
      const response = await knowledgeBaseKbApi.getKnowledgeBase(knowledgeBaseId);
      setKnowledgeBase(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных базы знаний',
        error: e,
      });
    }
  };

  const loadDialogsData = async (agentStageId: string) => {
    try {
      const sorting = ConversationSortDirection.LatestMessageOnDescending;
      const conversationsResponse = await conversationApi.searchConversations(
        undefined,
        agentStageId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        sorting,
        0,
        1
      );

      const conversationStatus = ConversationStatus.Closed;
      const closedConversationCountResponse = await conversationApi.getConversationCount(
        undefined,
        agentStageId,
        undefined,
        undefined,
        conversationStatus
      );

      const userCountResponse = await conversationApi.getConversationsUniqueUserCount(undefined, agentStageId);

      const totalCount = conversationsResponse.data.totalItemCount ?? 0;
      setDialogsBlockData({
        totalCount,
        activeCount: totalCount - closedConversationCountResponse.data,
        userCount: userCountResponse.data,
        latestConversation: totalCount ? conversationsResponse.data.items?.slice(-1)[0] : undefined,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке диалогов',
        error: e,
      });
    }
  };

  const loadAnalyticsData = async (agentStageId: string) => {
    try {
      const fromDate = moment().add(-6, 'd').startOf('d').format(ANALYTICS_DATE_FORMATS.parse);
      const toDate = moment().startOf('d').format(ANALYTICS_DATE_FORMATS.parse);

      const conversationsPerDayResponse = await analyticsReportsApi.getConversationsPerDayList(
        agentStageId,
        fromDate,
        toDate
      );

      setAnalyticsBlockData({
        conversationsPerDayList: conversationsPerDayResponse.data,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке аналитики',
        error: e,
      });
    }
  };

  const loadTrainingModelsData = async () => {
    try {
      const trainingModelsResponse = await botApi.getBotTrainingModels();

      setTrainingModels(trainingModelsResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка моделей обучения',
        error: e,
      });
    }
  };

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await botApi.getBot(id);
      const bot = response.data;
      if (bot.entry.knowledgeBaseId) {
        await loadKnowledgeBaseData(bot.entry.knowledgeBaseId);
      }

      const agentResponse = await agentApi.getAgent(bot.originStage.agentId);
      const agentStageId = agentResponse.data.productionAgent.id;

      await loadDialogsData(agentStageId);
      await loadAnalyticsData(agentStageId);
      await loadTrainingModelsData();

      setBot(bot);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных бота',
        error: e,
      });
    }
    setLoading(false);
  };

  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [id]);

  const onDataChanged = async () => await loadDataAsync();

  const onBackButtonClick = () => push('/simple-bots');

  const onKnowledgeBaseBlockClick = () => {
    if (knowledgeBase) {
      push(`/simple-bots/${bot?.entry.id}/knowledge-base`);
    }
  };

  const onSettingsButtonClick = () => !loading && setSettingsModalVisible(true);
  const onSettingsModalClose = () => setSettingsModalVisible(false);

  const onAnalyticsBlockClick = () => push(`/simple-bots/${bot?.entry.id}/analytics`);
  const onDialogsBlockClick = () => push(`/simple-bots/${bot?.entry.id}/dialogs`);
  const onChannelsBlockClick = () => {
    channelsClickable && push(`/simple-bots/${bot?.entry.id}/channels`);
  };

  const renderBackButton = () => {
    return (
      <SbButton sbSize="medium" sbType="tertiary" onClick={onBackButtonClick}>
        <SbIcon iconName="left" size={16} />К списку ботов
      </SbButton>
    );
  };

  if (loading && !bot) {
    return (
      <Col className="sb-bot-card">
        <Row>{renderBackButton()}</Row>
        <div className="sb-bot-card__loader">
          <Spin />
        </div>
      </Col>
    );
  }

  const onExport = (botStage?: BotStageModel) => async () => {
    if (!botStage) return;

    try {
      const response = await botEditionApi.exportBotEdition(
        botStage.currentEdition.botEditionId,
        BotContentFormat.Json,
        { responseType: 'blob' }
      );
      downloadNamedFile(response);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при экспорте бота',
        error: e,
      });
    }
  };

  const onDeleteDraft = async () => {
    if (!bot?.draftStage) return;

    try {
      await botStageApi.deleteBotStage(bot.draftStage.id);
      await loadDataAsync();
      setCurrentBotStageType(BotStageType.Origin);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении черновика бота',
        error: e,
      });
    }
  };

  const onImportBotFileUpload = (botStage?: BotStageModel) => async (file: RcFile, base64Content: string) => {
    if (!botStage) return;

    try {
      await botStageApi.importBot(botStage.id, {
        botFile: {
          fileName: file.name,
          mimeType: file.type,
          content: base64Content,
        },
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при импорте бота',
        error: e,
        description:
          e.response?.data?.title === 'InvalidCast' ? 'Загружаемый файл не является файлом описания бота' : undefined,
      });
    }
  };

  const onShowBotVersions = () => setBotVersionsModalVisible(true);
  const onBotVersionsModalClose = () => setBotVersionsModalVisible(false);

  const onBotVersionsModalDraftStageAdded = async () => {
    await loadDataAsync();
    setBotVersionsModalVisible(false);
  };

  const botActionsMenuContent =
    currentBotStageType === BotStageType.Draft ? (
      <Menu>
        <Menu.Item key="export" onClick={onExport(bot?.draftStage)}>
          Экспортировать черновик
        </Menu.Item>
        <Menu.Item key="import-as-draft">
          <SbUpload
            accept={ALLOWED_IMPORT_BOT_FILE_TYPES.join(',')}
            className="sb-bot-card__header__right__actions-button__import-upload"
            onFileUpload={onImportBotFileUpload(bot?.draftStage)}
          >
            Импортировать в черновик
          </SbUpload>
        </Menu.Item>
        <Menu.Item key="delete" onClick={onDeleteDraft}>
          Удалить черновик
        </Menu.Item>
        <Divider />
      </Menu>
    ) : (
      <Menu>
        <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
          <Menu.Item key="connect">Подключить мессенжер</Menu.Item>
        </Flags>
        <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
          <Menu.Item key="rename">Переименовать</Menu.Item>
        </Flags>
        <Menu.Item key="history" onClick={onShowBotVersions}>
          История версий
        </Menu.Item>
        <Menu.Item key="export" onClick={onExport(bot?.originStage)}>
          Экспортировать
        </Menu.Item>
        <Menu.Item key="import-as-origin">
          <SbUpload
            accept={ALLOWED_IMPORT_BOT_FILE_TYPES.join(',')}
            className="sb-bot-card__header__right__actions-button__import-upload"
            onFileUpload={onImportBotFileUpload(bot?.originStage)}
          >
            Импортировать
          </SbUpload>
        </Menu.Item>
        <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
          <Menu.Item key="delete">Удалить</Menu.Item>
        </Flags>
      </Menu>
    );

  const renderChannelsBlock = (span: number) => (
    <Col className={MAIN_BLOCK_CLASS_NAME} span={span} onClick={onChannelsBlockClick}>
      <div className={`${INNER_BLOCK_CLASS_NAME} ${channelsClickable ? INNER_HOVERABLE_BLOCK_CLASS_NAME : ''}`}>
        {bot && <ChannelsBlock bot={bot} onClickableChanged={setChannelsClickable} />}
      </div>
    </Col>
  );

  const renderKnowledgeBaseBlock = (span: number) => (
    <Col className={MAIN_BLOCK_CLASS_NAME} span={span} onClick={onKnowledgeBaseBlockClick}>
      <div className={`${INNER_BLOCK_CLASS_NAME} ${knowledgeBase ? INNER_HOVERABLE_BLOCK_CLASS_NAME : ''}`}>
        {bot && botStage && botCurrentEdition && (
          <KnowledgeBaseBlock
            bot={bot}
            botEdition={botCurrentEdition}
            botStageId={botStage.id}
            knowledgeBase={knowledgeBase}
            onDataChanged={onDataChanged}
          />
        )}
      </div>
    </Col>
  );

  const renderDialogsBlock = (span: number) => (
    <Col className={MAIN_BLOCK_CLASS_NAME} span={span} onClick={onDialogsBlockClick}>
      <div className={`${INNER_BLOCK_CLASS_NAME} ${INNER_HOVERABLE_BLOCK_CLASS_NAME}`}>
        {bot && (
          <DialogsBlock
            activeCount={dialogsBlockData.activeCount}
            latestConversation={dialogsBlockData.latestConversation}
            totalCount={dialogsBlockData.totalCount}
            userCount={dialogsBlockData.userCount}
          />
        )}
      </div>
    </Col>
  );

  const renderAnalyticsBlock = (span: number) => (
    <Col className={MAIN_BLOCK_CLASS_NAME} span={span} onClick={onAnalyticsBlockClick}>
      <div className={`${INNER_BLOCK_CLASS_NAME} ${INNER_HOVERABLE_BLOCK_CLASS_NAME}`}>
        {bot && <AnalyticsBlock conversationsPerDayList={analyticsBlockData.conversationsPerDayList} />}
      </div>
    </Col>
  );

  const renderScenarioListBlock = (span: number) => (
    <Col className={MAIN_BLOCK_CLASS_NAME} span={span}>
      <div className={INNER_BLOCK_CLASS_NAME}>
        {bot && <ScenarioListBlock bot={bot} onDataChanged={onDataChanged} />}
      </div>
    </Col>
  );

  return (
    <Col className="sb-bot-card">
      <Row>{renderBackButton()}</Row>
      <Row className="sb-bot-card__header">
        <Col className="sb-bot-card__header__left">
          <Row>
            <Title>
              {bot?.entry.name}
              {isDraft && <span> (Черновик)</span>}
            </Title>
          </Row>
        </Col>
        <Col className="sb-bot-card__header__right">
          <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
            <SbButton sbSize="big" sbType="secondary">
              Подключить
            </SbButton>
          </Flags>
          <SbButton icon={<SbIcon iconName="setting-two" />} sbType="icon-primary-48" onClick={onSettingsButtonClick} />
          <div className="sb-bot-card__header__right__actions-button">
            <SbContextMenu menuContent={botActionsMenuContent}>
              <SbButton sbSize="big" sbType="secondary">
                Действия с ботом
              </SbButton>
            </SbContextMenu>
          </div>
          <WebchatButton botName={bot?.entry.name} botStage={botStage} />
        </Col>
      </Row>
      <Row className="sb-bot-card__content">
        <Col span={24}>
          <Flags
            authorizedFlags={[FeatureFlagNames.SIMPLE_BOT_CHANNELS_MANAGEMENT]}
            renderOff={() => (
              <Flags
                authorizedFlags={[FeatureFlagNames.ANALYTICS]}
                renderOff={() => {
                  return null;
                }}
                renderOn={() => <Row>{renderAnalyticsBlock(24)}</Row>}
              />
            )}
            renderOn={() => (
              <Flags
                authorizedFlags={[FeatureFlagNames.ANALYTICS]}
                renderOff={() => <Row>{renderChannelsBlock(24)}</Row>}
                renderOn={() => (
                  <Row>
                    {renderChannelsBlock(12)}
                    {renderAnalyticsBlock(12)}
                  </Row>
                )}
              />
            )}
          />
          <Row>
            {renderKnowledgeBaseBlock(12)}
            {renderDialogsBlock(12)}
          </Row>
          <Row>{renderScenarioListBlock(24)}</Row>
        </Col>
      </Row>
      {botStage && botCurrentEdition && (
        <SettingsModal
          botEdition={botCurrentEdition}
          botStageId={botStage?.id}
          trainingModels={trainingModels}
          visible={settingsModalVisible}
          onClose={onSettingsModalClose}
          onDataChanged={onDataChanged}
        />
      )}
      {bot?.entry.id && (
        <SbBotVersionsModal
          botId={bot.entry.id}
          visible={botVersionsModalVisible}
          onClose={onBotVersionsModalClose}
          onDraftStageAdded={onBotVersionsModalDraftStageAdded}
        />
      )}
    </Col>
  );
};

export default SimpleBotCard;
