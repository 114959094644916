import React, { useState } from 'react';
import { Avatar, Badge } from 'antd';
import moment from 'moment/moment';
import ReactMarkdown from 'react-markdown';

import './index.less';

import { DirectLineIcon, Elma365Icon, LiveChatIcon, Logo, TelegramIcon, ViberIcon } from '../../../../assets';
import { ActivityDirection } from '../../../../../api';
import SbIcon from '../../../components/common/SbIcon';
import SbTag from '../../../components/common/SbTag';
import { DIALOGS_COUNT_TAG_COLOR } from '../../../const';
import { Channels } from '../../../../constants';
import { WhatsAppIcon } from '../../../components/common/SbChannelIcon/icons';
import { DialogItem } from '../../../utils/dialogs';

const DATE_FORMAT = 'DD MMM, YYYY в HH:mm';
const USER_AVATAR_BACKGROUND_COLOR = '#82B8F3';
const BOT_AVATAR_BACKGROUND_COLOR = '#9CE3E1';
const SELECTED_AVATAR_BACKGROUND_COLOR = '#0074E0';
const AVATAR_SIZE = 69;
const CHANNEL_ICON_SIZE = 20;
const CHANNEL_ICON_PROPERTIES = {
  height: CHANNEL_ICON_SIZE,
  width: CHANNEL_ICON_SIZE,
  style: { marginTop: 5 },
};

interface IDialogListItemProps {
  item: DialogItem;
  onSelection: () => void;
}

const DialogListItem: React.FC<IDialogListItemProps> = ({ item, onSelection }) => {
  const [isHovered, setIsHovered] = useState(false);

  const getChannelIcon = () => {
    switch (item.model.channelId) {
      case Channels.TELEGRAM:
        return <TelegramIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.DIRECTLINE:
        return <DirectLineIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.VIBER:
        return <ViberIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.WHATSAPP:
        return <WhatsAppIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.ELMA365:
        return <Elma365Icon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.LIVECHAT:
        return <LiveChatIcon {...CHANNEL_ICON_PROPERTIES} />;
      default:
        return <Logo {...CHANNEL_ICON_PROPERTIES} />;
    }
  };

  const onAvatarClick = () => {
    onSelection();
  };

  const onAvatarHover = () => setIsHovered(true);

  const onAvatarOut = () => {
    setIsHovered(false);
  };

  return (
    <div className="sb-dialogs-card__content__list-container__list__item">
      <div onClick={onAvatarClick} onFocus={onAvatarHover} onMouseLeave={onAvatarOut} onMouseOver={onAvatarHover}>
        <Badge count={<Avatar icon={getChannelIcon()} size={30} />} offset={[-11, 58]}>
          {item.isSelected ? (
            <Avatar
              icon={<SbIcon iconName="check" />}
              size={AVATAR_SIZE}
              style={{ background: SELECTED_AVATAR_BACKGROUND_COLOR }}
            />
          ) : isHovered ? (
            <Avatar
              icon={<SbIcon iconName="check" />}
              size={AVATAR_SIZE}
              style={{
                background:
                  item.model.latestMessageDirection === ActivityDirection.NUMBER_0
                    ? USER_AVATAR_BACKGROUND_COLOR
                    : BOT_AVATAR_BACKGROUND_COLOR,
              }}
            />
          ) : item.model.latestMessageDirection === ActivityDirection.NUMBER_0 ? (
            <Avatar
              icon={<SbIcon iconName="user" />}
              size={AVATAR_SIZE}
              style={{ background: USER_AVATAR_BACKGROUND_COLOR }}
            />
          ) : (
            <Avatar
              icon={<SbIcon iconName="robot" />}
              size={AVATAR_SIZE}
              style={{ background: BOT_AVATAR_BACKGROUND_COLOR }}
            />
          )}
        </Badge>
      </div>
      <div className="sb-dialogs-card__content__list-container__list__item__text">
        <span className="sb-dialogs-card__content__list-container__list__item__text_main">
          {moment(item.model.latestMessageOn ?? item.model.startedOn).format(DATE_FORMAT)}
          <SbTag color={DIALOGS_COUNT_TAG_COLOR} sbSize="small" text={item.model.messagesCount} />
        </span>
        <span>
          <ReactMarkdown>{item.model.latestMessageText ?? ''}</ReactMarkdown>
        </span>
      </div>
    </div>
  );
};

export default DialogListItem;
