import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import IbModal, { IbModalPosition } from '../common/IbModal';
import {
  AgentStageAccountStatus,
  InboxChannelCreationRequest,
  InboxChannelModel,
  InboxChannelOperatorsRoutingType,
  InboxChannelRoutingBotAssignmentType,
  InboxChannelUpdatingRequest,
  LiveChatSettingsModel,
} from '../../../../api';
import IbButton from '../common/IbButton';
import IbTypography from '../common/IbTypography';
import IbInput from '../common/IbInput';
import {
  Channels,
  ENABLE_INLINE_BUTTONS_PROPERTY_KEY,
  LIVE_CHAT_SETTINGS_DEFAULT,
  SETTINGS_PROPERTY_KEY,
} from '../../../constants';
import IbSocial, { IbSocialType } from '../common/IbSocial';
import { getChannelName, getLivechatCodeSnippetScript } from '../../../utils/stringUtil';
import IbRadio from '../common/IbRadio';
import IbBadge from '../common/IbBadge';
import { IFormFieldValidationResult } from '../../../types';
import IbIcon from '../common/IbIcon';
import IbTextArea from '../common/IbTextArea';
import IbSwitch from '../common/IbSwitch';

import LiveChatSettings from './LiveChatSettings';
import {
  CHANNEL_SELECTION_CLASS_NAME,
  CHANNEL_SELECTION_LIST_CLASS_NAME,
  CHANNEL_SELECTION_LIST_ITEM_CLASS_NAME,
  CODE_SNIPPET_CLASS_NAME,
  CODE_TEXTAREA_ROWS,
  FORM_ITEM_CLASS_NAME,
  FORM_ITEM_SWITCH_CLASS_NAME,
  INSTRUCTION_CLASS_NAME,
  MAIN_CLASS_NAME,
  SHOW_CODE_COPIED_TIMEOUT,
  WIDE_CLASS_NAME,
} from './const';

const WORKTIME_START = '06:00'; // utc, 9:00 МСК
const WORKTIME_END = '15:00'; // utc, 18:00 МСК
export const INBOX_CHANNEL_DEFAULT = {
  id: '',
  agentStageAccount: {} as unknown,
  authToken: '',
  channelId: '',
  displayName: '',
  status: AgentStageAccountStatus.Enabled,
  properties: undefined,
  routing: {
    operators: {
      routingType: InboxChannelOperatorsRoutingType.Auto,
      maxChatCountPerOperator: undefined,
      operatorResponseTime: undefined,
      defaultGroup: undefined,
      additionalGroups: [],
    },
    bot: {
      enabled: false,
      botEntry: undefined,
      assignmentType: InboxChannelRoutingBotAssignmentType.Manually,
    },
    workTime: {
      enabled: true,
      monday: {
        enabled: true,
        start: WORKTIME_START,
        end: WORKTIME_END,
      },
      tuesday: {
        enabled: true,
        start: WORKTIME_START,
        end: WORKTIME_END,
      },
      wednesday: {
        enabled: true,
        start: WORKTIME_START,
        end: WORKTIME_END,
      },
      thursday: {
        enabled: true,
        start: WORKTIME_START,
        end: WORKTIME_END,
      },
      friday: {
        enabled: true,
        start: WORKTIME_START,
        end: WORKTIME_END,
      },
      saturday: {
        enabled: false,
        start: WORKTIME_START,
        end: WORKTIME_END,
      },
      sunday: {
        enabled: false,
        start: WORKTIME_START,
        end: WORKTIME_END,
      },
    },
  },
} as InboxChannelModel;

enum ModalStep {
  CHANNEL_SELECTION = 'CHANNEL_SELECTION',
  SETTINGS = 'SETTINGS',
  DONE = 'DONE',
}

export interface IChannelEditFormValidationResult {
  name: IFormFieldValidationResult;
  token: IFormFieldValidationResult;
}

const formIsValid = (validationResult: IChannelEditFormValidationResult) =>
  validationResult.name.isValid && validationResult.token.isValid;

interface IIbChannelEditModalProps {
  visible?: boolean;
  editChannel?: InboxChannelModel;
  addedAgentStageAccountId?: string;
  livechatBasePath: string;
  onAdd?: (request: InboxChannelCreationRequest) => Promise<IChannelEditFormValidationResult | undefined>;
  onEdit?: (request: InboxChannelUpdatingRequest) => Promise<IChannelEditFormValidationResult | undefined>;
  onCancel?: () => void;
}

const IbChannelEditModal: React.FC<IIbChannelEditModalProps> = ({
  visible,
  editChannel,
  addedAgentStageAccountId,
  livechatBasePath,
  onAdd,
  onEdit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [showCodeCopied, setShowCodeCopied] = useState(false);
  const [showCodeCopiedTimer, setShowCodeCopiedTimer] = useState<NodeJS.Timeout>();

  const [step, setStep] = useState(ModalStep.DONE);
  const [selectedChannelId, setSelectedChannelId] = useState<string>();
  const [saving, setSaving] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [channel, setChannel] = useState({} as InboxChannelModel);
  const [outsideValidation, setOutsideValidation] = useState<IChannelEditFormValidationResult>();

  const isChannelSelectionStep = step === ModalStep.CHANNEL_SELECTION;
  const isChannelDoneStep = step === ModalStep.DONE;
  const selectedChannelIsLivechat = selectedChannelId === Channels.LIVECHAT;

  const validationResult: IChannelEditFormValidationResult = outsideValidation
    ? outsideValidation
    : {
        name: {
          isValid: !!channel.displayName,
          message: t('Enter name'),
        },
        token: {
          isValid: selectedChannelIsLivechat || !!channel.authToken,
          message: t('Enter token'),
        },
      };

  const classes = [MAIN_CLASS_NAME];
  if (isChannelSelectionStep) classes.push(CHANNEL_SELECTION_CLASS_NAME);
  if (selectedChannelIsLivechat && step === ModalStep.SETTINGS) classes.push(WIDE_CLASS_NAME);

  const onChannelListItemClick = (channelId: string) => () => setSelectedChannelId(channelId);

  const onNextButtonClick = () => {
    if (selectedChannelIsLivechat) {
      setChannel({
        ...channel,
        properties: {
          [SETTINGS_PROPERTY_KEY]: LIVE_CHAT_SETTINGS_DEFAULT,
        },
      });
    }
    setStep(ModalStep.SETTINGS);
  };

  const onSaveButtonClick = async () => {
    setShowValidation(true);

    if (!editChannel || !formIsValid(validationResult)) {
      return;
    }

    setSaving(true);
    const outsideValidationResult = await onEdit?.({
      authToken: channel.authToken,
      displayName: channel.displayName,
      status: channel.status,
      properties: channel.properties,
      routing: channel.routing,
    });
    setSaving(false);

    if (!outsideValidationResult) {
      return;
    }

    if (formIsValid(outsideValidationResult)) {
      onCancel?.();
      return;
    }

    setOutsideValidation(outsideValidationResult);
  };

  const onAddButtonClick = async () => {
    setShowValidation(true);

    if (!formIsValid(validationResult) || !selectedChannelId) {
      return;
    }

    setSaving(true);
    const outsideValidationResult = await onAdd?.({
      channelId: selectedChannelId,
      authToken: selectedChannelIsLivechat ? v4() : channel.authToken,
      displayName: channel.displayName,
      status: AgentStageAccountStatus.Enabled,
      properties: channel.properties,
      routing: channel.routing,
    });
    setSaving(false);

    if (!outsideValidationResult) {
      return;
    }

    if (formIsValid(outsideValidationResult) && selectedChannelIsLivechat) {
      setStep(ModalStep.DONE);
      return;
    }

    if (formIsValid(outsideValidationResult)) {
      onCancel?.();
      return;
    }

    setOutsideValidation(outsideValidationResult);
  };

  const onNameChange = (value: string) => setChannel({ ...channel, displayName: value });
  const onTokenChange = (value: string) => setChannel({ ...channel, authToken: value });

  const onLiveChatSettingsChange = (settings: LiveChatSettingsModel) =>
    setChannel({
      ...channel,
      properties: {
        ...channel.properties,
        [SETTINGS_PROPERTY_KEY]: settings,
      },
    });

  const onEnableInlineButtonsChange = (value: boolean) =>
    setChannel({
      ...channel,
      properties: {
        ...channel.properties,
        [ENABLE_INLINE_BUTTONS_PROPERTY_KEY]: value,
      },
    });

  const onVisiblePropChange = () => {
    if (!visible) {
      return;
    }

    setSaving(false);
    setShowValidation(false);
    setChannel(cloneDeep(editChannel ? editChannel : INBOX_CHANNEL_DEFAULT));
    setStep(editChannel ? ModalStep.SETTINGS : ModalStep.CHANNEL_SELECTION);
    setSelectedChannelId(editChannel ? editChannel.channelId : undefined);
  };
  useEffect(onVisiblePropChange, [visible]);

  const onChannelChange = () => setOutsideValidation(undefined);
  useEffect(onChannelChange, [channel]);

  const onShowCodeCopiedChange = () => {
    if (showCodeCopied) {
      !!showCodeCopiedTimer && clearTimeout(showCodeCopiedTimer);
      setShowCodeCopiedTimer(setTimeout(() => setShowCodeCopied(false), SHOW_CODE_COPIED_TIMEOUT));
    }
  };
  useEffect(onShowCodeCopiedChange, [showCodeCopied]);

  const renderTitle = () => {
    if (editChannel) {
      return t('Set up channel');
    }

    if (isChannelSelectionStep) {
      return t('Add channel');
    }

    if (isChannelDoneStep) {
      return t('Widget integration');
    }

    return t('Add {{name}}', { name: getChannelName(selectedChannelId) });
  };

  const renderFooter = () => {
    if (editChannel) {
      return (
        <>
          <IbButton disabled={saving} onClick={onSaveButtonClick}>
            {t('Save')}
          </IbButton>
          <IbButton type="secondary" onClick={onCancel}>
            {t('Cancel')}
          </IbButton>
          <IbButton type="fill" onClick={onCancel}>
            {t('Cancel (verb)')}
          </IbButton>
        </>
      );
    }

    if (isChannelSelectionStep) {
      return (
        <>
          <IbButton disabled={!selectedChannelId} onClick={onNextButtonClick}>
            {t('Next')}
          </IbButton>
          <IbButton type="secondary" onClick={onCancel}>
            {t('Cancel')}
          </IbButton>
          <IbButton type="fill" onClick={onCancel}>
            {t('Cancel (verb)')}
          </IbButton>
        </>
      );
    }

    if (isChannelDoneStep) {
      return <IbButton onClick={onCancel}>{t('Ready')}</IbButton>;
    }

    return (
      <>
        <IbButton disabled={saving} onClick={onAddButtonClick}>
          {t('Add')}
        </IbButton>
        <IbButton type="secondary" onClick={onCancel}>
          {t('Cancel')}
        </IbButton>
        <IbButton type="fill" onClick={onCancel}>
          {t('Cancel (verb)')}
        </IbButton>
      </>
    );
  };

  const renderConnectionInstruction = () => {
    if (editChannel) return null;

    const header = (
      <IbTypography.Paragraph strong type="secondary">
        {t('Connection instructions')}
      </IbTypography.Paragraph>
    );

    switch (selectedChannelId) {
      case Channels.TELEGRAM:
        return (
          <div className={INSTRUCTION_CLASS_NAME}>
            {header}
            <ul>
              <li>
                <IbBadge disabled value="1" />
                <IbTypography.Paragraph type="secondary">
                  {t('Connection instruction.telegram.step 1.part 1')}{' '}
                  <a href="https://t.me/BotFather" rel="noreferrer" target="_blank">
                    @BotFather
                  </a>
                </IbTypography.Paragraph>
              </li>
              <li>
                <IbBadge disabled value="2" />
                <IbTypography.Paragraph type="secondary">
                  {t('Connection instruction.telegram.step 2.part 1')} <b>/newbot </b>
                  {t('Connection instruction.telegram.step 2.part 2')}
                </IbTypography.Paragraph>
              </li>
              <li>
                <IbBadge disabled value="3" />
                <IbTypography.Paragraph type="secondary">
                  {t('Connection instruction.telegram.step 3.part 1')}
                </IbTypography.Paragraph>
              </li>
              <li>
                <IbBadge disabled value="4" />
                <IbTypography.Paragraph type="secondary">
                  {t('Connection instruction.telegram.step 4.part 1')} <b>username </b>
                  {t('Connection instruction.telegram.step 4.part 2')}
                </IbTypography.Paragraph>
              </li>
              <li>
                <IbBadge disabled value="4" />
                <IbTypography.Paragraph type="secondary">
                  {t('Connection instruction.telegram.step 5.part 1')}
                </IbTypography.Paragraph>
              </li>
            </ul>
          </div>
        );
      case Channels.VIBER:
        return (
          <div className={INSTRUCTION_CLASS_NAME}>
            {header}
            <ul>
              <li>
                <IbBadge disabled value="1" />
                <IbTypography.Paragraph type="secondary">
                  {t('Connection instruction.viber.step 1.part 1')}{' '}
                  <a href="https://partners.viber.com" rel="noreferrer" target="_blank">
                    {t('Connection instruction.viber.step 1.part 2')}
                  </a>{' '}
                  {t('Connection instruction.viber.step 1.part 3')}
                </IbTypography.Paragraph>
              </li>
              <li>
                <IbBadge disabled value="2" />
                <IbTypography.Paragraph type="secondary">
                  {t('Connection instruction.viber.step 2.part 1')} <b>Create Bot Account</b>
                  {t('Connection instruction.viber.step 2.part 2')} <b>Create</b>.
                </IbTypography.Paragraph>
              </li>
              <li>
                <IbBadge disabled value="3" />
                <IbTypography.Paragraph type="secondary">
                  {t('Connection instruction.viber.step 3.part 1')} <b>Copy</b>
                  {t('Connection instruction.viber.step 3.part 2')}
                </IbTypography.Paragraph>
              </li>
            </ul>
          </div>
        );
      default:
        return null;
    }
  };

  const renderChannelSelectionModalStep = () => (
    <>
      <IbTypography>{t('Channel selection description')}</IbTypography>
      <div className={CHANNEL_SELECTION_LIST_CLASS_NAME}>
        {[Channels.TELEGRAM, Channels.VIBER, Channels.LIVECHAT].map((channelId) => (
          <div
            key={channelId}
            className={CHANNEL_SELECTION_LIST_ITEM_CLASS_NAME}
            onClick={onChannelListItemClick(channelId)}
          >
            <IbSocial social={channelId as IbSocialType} />
            <IbTypography.Paragraph strong>{getChannelName(channelId)}</IbTypography.Paragraph>
            <IbRadio checked={channelId === selectedChannelId} />
          </div>
        ))}
      </div>
    </>
  );

  const renderLivechatCodeSnippet = () => {
    if (!selectedChannelIsLivechat) {
      return null;
    }

    const agentStageAccountId = channel.agentStageAccount.id || addedAgentStageAccountId;
    if (!agentStageAccountId) {
      return null;
    }

    const script = getLivechatCodeSnippetScript(livechatBasePath, agentStageAccountId, 'userData');

    const onCopyCodeClick = () => {
      navigator.clipboard.writeText(script);
      setShowCodeCopied(true);
    };

    return (
      <div className={CODE_SNIPPET_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Code to embed on the site')}</IbTypography.Paragraph>
        <IbTextArea rows={CODE_TEXTAREA_ROWS} value={script} />
        {showCodeCopied ? (
          <IbButton icon={<IbIcon iconName="check" />} status="success" type="link">
            {t('Copied')}
          </IbButton>
        ) : (
          <IbButton icon={<IbIcon iconName="copy-one" />} type="link" onClick={onCopyCodeClick}>
            {t('Copy code')}
          </IbButton>
        )}
      </div>
    );
  };

  const renderSettingsModalStep = () => (
    <>
      {renderConnectionInstruction()}

      {selectedChannelIsLivechat ? (
        <LiveChatSettings
          codeSnippet={renderLivechatCodeSnippet()}
          name={channel.displayName}
          nameIsValid={validationResult.name.isValid}
          settings={channel.properties?.[SETTINGS_PROPERTY_KEY] as LiveChatSettingsModel}
          showValidation={showValidation}
          onNameChange={onNameChange}
          onSettingsChange={onLiveChatSettingsChange}
        />
      ) : (
        <>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('Token')}</IbTypography.Paragraph>
            <IbInput
              status={showValidation && !validationResult.token.isValid ? 'error' : 'default'}
              value={channel.authToken}
              onChange={onTokenChange}
            />
            {showValidation && !validationResult.token.isValid && (
              <IbTypography.Paragraph error type="descriptor">
                {validationResult.token.message}
              </IbTypography.Paragraph>
            )}
          </div>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('Name2')}</IbTypography.Paragraph>
            <IbInput
              status={showValidation && !validationResult.name.isValid ? 'error' : 'default'}
              value={channel.displayName}
              onChange={onNameChange}
            />
            {showValidation && !validationResult.name.isValid && (
              <IbTypography.Paragraph error type="descriptor">
                {validationResult.name.message}
              </IbTypography.Paragraph>
            )}
          </div>
          {channel.channelId === Channels.TELEGRAM && (
            <div className={FORM_ITEM_SWITCH_CLASS_NAME}>
              <IbSwitch
                checked={channel.properties?.[ENABLE_INLINE_BUTTONS_PROPERTY_KEY]}
                onChange={onEnableInlineButtonsChange}
              />
              <IbTypography.Paragraph type="secondary">{t('Use Inline buttons')}</IbTypography.Paragraph>
            </div>
          )}
        </>
      )}
    </>
  );

  const renderDoneModalStep = () => {
    if (!selectedChannelIsLivechat) {
      return null;
    }

    return (
      <>
        <div className={INSTRUCTION_CLASS_NAME}>
          <IbTypography.Paragraph strong type="secondary">
            {t('Connection instructions')}
          </IbTypography.Paragraph>
          <ul>
            <li>
              <IbBadge disabled value="1" />
              <IbTypography.Paragraph type="secondary">
                {t('Connection instruction.livechat.step 1.part 1')} <b>&lt;/body&gt;</b>
                {t('Connection instruction.livechat.step 1.part 2')}
              </IbTypography.Paragraph>
            </li>
            <li>
              <IbBadge disabled value="2" />
              <IbTypography.Paragraph type="secondary">
                {t('Connection instruction.livechat.step 2.part 1')}
              </IbTypography.Paragraph>
            </li>
          </ul>
        </div>
        {renderLivechatCodeSnippet()}
        <IbTypography.Paragraph disabled type="secondary">
          {t(
            'In the future, you can find a code fragment and also change the display of the widget in the settings menu on the channel card'
          )}
        </IbTypography.Paragraph>
      </>
    );
  };

  const renderModalContent = () => {
    switch (step) {
      case ModalStep.CHANNEL_SELECTION:
        return renderChannelSelectionModalStep();
      case ModalStep.SETTINGS:
        return renderSettingsModalStep();
      case ModalStep.DONE:
        return renderDoneModalStep();
    }
  };

  return (
    <IbModal
      className={classes.join(' ')}
      footer={renderFooter()}
      loading={saving}
      position={IbModalPosition.FixedTall}
      title={renderTitle()}
      visible={visible}
      onCancel={onCancel}
    >
      {renderModalContent()}
    </IbModal>
  );
};

export default IbChannelEditModal;
