import React, { useEffect, useState, KeyboardEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { SelectValue } from 'antd/lib/select';
import moment from 'moment';
import { Flags } from 'react-feature-flags';
import { Col, Row, Collapse } from 'antd';

import './index.less';

import SbModal from '../../../../components/common/SbModal';
import SbButton from '../../../../components/common/SbButton';
import SbTypography from '../../../../components/common/SbTypography';
import SbInput from '../../../../components/common/SbInput';
import SbSwitch from '../../../../components/common/SbSwitch';
import {
  BotEditionModel,
  ConversationClosingSettingsSchema,
  Elma365IntegrationSettingsSchema,
  LocalizationSettingsSchema,
  FeatureFlag,
  RecognitionSettingsSchema,
  SchemaKind,
  ScriptSettingsSchema,
  SmtpSettingsSchema,
  NlpSettingsSchema,
  BotTrainingModelModel,
} from '../../../../../../api';
import { generateId, instanceOfElma365IntegrationSettingsSchema } from '../../../../../components/ScenarioEditor/utils';
import { agentApi, botApi, botStageApi, tenantAuthApi } from '../../../../../apis';
import { AlertTypes, FeatureFlagNames } from '../../../../../constants';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import SbTabs from '../../../../components/common/SbTabs';
import SbTabPane from '../../../../components/common/SbTabPane';
import SbIcon from '../../../../components/common/SbIcon';
import SbScriptEditor from '../../../../components/common/SbScriptEditor';
import { LOCALE_NAMES, SCRIPT_EXECUTION_TIMEOUT_DEFAULT } from '../../../../const';
import SbTooltip from '../../../../components/common/SbTooltip';
import SbSelect from '../../../../components/common/SbSelect';
import SbSlider from '../../../../components/common/SbSlider';
import SbPanel from '../../../../components/common/SbPanel';
import { getFeatureFlags } from '../../../../../utils/featureFlagsUtil';
import SbScroll from '../../../../components/common/SbScroll';
import { getConfig } from '../../../../../utils/configUtil';

import Elma365IntegrationCheckButton from './Elma365IntegrationCheckButton';

const { Panel } = Collapse;

enum TabPaneKeys {
  GENERAL = 'GENERAL',
  SCRIPT = 'SCRIPT',
  INTEGRATIONS = 'INTEGRATIONS',
  SMTP = 'SMTP',
  RECOGNITION = 'RECOGNITION',
  API = 'API',
  ADDITIONAL = 'ADDITIONAL',
}

const ELMA365_INTEGRATION_NAME = 'ELMA365';
const ELMA365_INTEGRATION_CODE = 'elma365';
const SCRIPT_RETRY_COUNT_DEFAULT = 0;
const SCRIPT_RETRY_DELAY_DEFAULT = 1;
const SMTP_PORT_DEFAULT = 587;
const SMTP_USE_SSL_DEFAULT = true;
const DEFAULT_INTENT_THRESHOLD = 0.6;
const INTENT_THRESHOLD_MIN_VALUE = 0;
const INTENT_THRESHOLD_MAX_VALUE = 1;
const INTENT_THRESHOLD_STEP = 0.01;
const CONVERSATION_CLOSING_TIMEOUT_MINUTES_MIN_VALUE = 10;
const LOW_CONVERSATION_CLOSING_TIMEOUT_MINUTES_MIN_VALUE = 1;
const CONVERSATION_CLOSING_TIMEOUT_MINUTES_DEFAULT_VALUE = 30;
const SHOW_AGENT_STAGE_ID_COPIED_TIMEOUT = 2000;
const SHOW_XTOKEN_COPIED_TIMEOUT = 2000;

const secondsToRoundMinutesString = (seconds: number) =>
  moment.duration(seconds, 'seconds').asMinutes().toFixed().toString();

const BotLanguages = [
  { value: LOCALE_NAMES.russian, label: 'Русский' },
  { value: LOCALE_NAMES.english, label: 'Английский' },
];

interface ISettingsModalProps {
  botStageId: string;
  botEdition: BotEditionModel;
  visible: boolean;
  trainingModels: BotTrainingModelModel[];
  onClose: () => void;
  onDataChanged: () => Promise<void>;
}

const SettingsModal: React.FC<ISettingsModalProps> = ({
  botStageId,
  botEdition,
  visible,
  trainingModels,
  onClose,
  onDataChanged,
}) => {
  const { id } = useParams<{ id: string }>();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [saving, setSaving] = useState(false);
  const [currentTabPane, setCurrentTabPane] = useState(TabPaneKeys.GENERAL);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);

  const [scriptContent, setScriptContent] = useState('');
  const [scriptExecutionTimeout, setScriptExecutionTimeout] = useState(0);
  const [scriptRetryCount, setScriptRetryCount] = useState(0);
  const [scriptRetryDelay, setScriptRetryDelay] = useState(0);
  const [integrationApiUrl, setIntegrationApiUrl] = useState('');
  const [integrationXToken, setIntegrationXToken] = useState('');
  const [agentStageId, setAgentStageId] = useState('');
  const [tenantXToken, setTenantXToken] = useState('');
  const [smtpHost, setSmtpHost] = useState('');
  const [smtpUsername, setSmtpUsername] = useState('');
  const [smtpPassword, setSmtpPassword] = useState('');
  const [smtpName, setSmtpName] = useState('');
  const [smtpPort, setSmtpPort] = useState(0);
  const [smtpUseSsl, setSmtpUseSsl] = useState(true);
  const [intentThreshold, setIntentThreshold] = useState(0);
  const [conversationClosingTimeoutEnabled, setConversationClosingTimeoutEnabled] = useState(false);
  const [conversationClosingTimeoutMinutesString, setConversationClosingTimeoutMinutesString] = useState(
    CONVERSATION_CLOSING_TIMEOUT_MINUTES_DEFAULT_VALUE.toString()
  );
  const [conversationClosingMessage, setConversationClosingMessage] = useState('');

  const [showAgentStageIdCopied, setShowAgentStageIdCopied] = useState(false);
  const [showAgentStageIdCopiedTimer, setShowAgentStageIdCopiedTimer] = useState<NodeJS.Timeout>();
  const [showXTokenCopied, setShowXTokenCopied] = useState(false);
  const [showXTokenCopiedTimer, setShowXTokenCopiedTimer] = useState<NodeJS.Timeout>();

  const [botLocale, setBotLocale] = useState<string>();
  const [useCustomTrainingModel, setUseCustomTrainingModel] = useState(false);
  const [customTrainingModel, setCustomTrainingModel] = useState<string>();

  const loadAgentStageIdAsync = async () => {
    try {
      const botResponse = await botApi.getBot(id);
      const agentResponse = await agentApi.getAgent(botResponse.data.originStage.agentId);
      setAgentStageId(agentResponse.data.productionAgent.id);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных бота',
        error: e,
      });
    }
  };

  const loadAgentStageId = () => {
    loadAgentStageIdAsync().finally();
  };
  useEffect(loadAgentStageId, []);

  const loadTenantXTokenAsync = async () => {
    try {
      const tenant = await tenantAuthApi.getCurrentTenant();
      setTenantXToken(tenant.data.xToken);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при получении X-Token',
        error: e,
      });
    }
  };
  const loadTenantXToken = () => {
    loadTenantXTokenAsync().finally();
  };
  useEffect(loadTenantXToken, []);

  const trainingModelsOptions = trainingModels.map((m) => {
    return { value: m.code, label: m.displayName, description: m.description };
  });

  const minimumAllowedTimeoutMinutes = featureFlags.find(
    (f) => f.name === FeatureFlagNames.LOW_CONVERSATION_CLOSING_TIMEOUT_MIN_VALUE
  )?.isActive
    ? LOW_CONVERSATION_CLOSING_TIMEOUT_MINUTES_MIN_VALUE
    : CONVERSATION_CLOSING_TIMEOUT_MINUTES_MIN_VALUE;

  const integration = ((botEdition.structure.settings.integrations || []).find((i) =>
    instanceOfElma365IntegrationSettingsSchema(i)
  ) || {
    id: generateId('EIS'),
    $kind: SchemaKind.Elma365IntegrationSettings,
    name: ELMA365_INTEGRATION_NAME,
    code: ELMA365_INTEGRATION_CODE,
    integrationId: '',
    apiUrl: '',
    xToken: '',
    organizationId: '',
  }) as Elma365IntegrationSettingsSchema;

  const script =
    botEdition?.structure.settings.script ||
    ({
      content: '',
      executionTimeout: SCRIPT_EXECUTION_TIMEOUT_DEFAULT,
      retryCount: SCRIPT_RETRY_COUNT_DEFAULT,
      retryDelay: SCRIPT_RETRY_DELAY_DEFAULT,
    } as ScriptSettingsSchema);

  const smtp =
    botEdition?.structure.settings.smtp ||
    ({
      host: '',
      username: '',
      password: '',
      name: '',
      port: SMTP_PORT_DEFAULT,
      useSsl: SMTP_USE_SSL_DEFAULT,
    } as SmtpSettingsSchema);

  const recognition =
    botEdition?.structure.settings.recognition ||
    ({
      intentThreshold: DEFAULT_INTENT_THRESHOLD,
    } as RecognitionSettingsSchema);

  const conversationClosing =
    botEdition?.structure.settings.conversationClosing ||
    ({
      closingMessage: '',
      closingTimeoutSeconds: minimumAllowedTimeoutMinutes * 60,
      closingEnabled: false,
    } as ConversationClosingSettingsSchema);

  const localization =
    botEdition?.structure.settings.localization || ({ locale: LOCALE_NAMES.default } as LocalizationSettingsSchema);

  const nlp =
    botEdition?.structure.settings.recognition.nlp ||
    ({ useCustomTrainingModel: false, customTrainingModel: undefined } as NlpSettingsSchema);

  const loadFeatureFlagsAsync = async () => {
    const featureFlags = await getFeatureFlags();
    setFeatureFlags(featureFlags);
  };

  const loadFeatureFlags = () => {
    loadFeatureFlagsAsync().finally();
  };

  useEffect(loadFeatureFlags, []);

  const saveSettings = async () => {
    if (
      scriptContent === script.content &&
      scriptExecutionTimeout === script.executionTimeout &&
      scriptRetryCount === script.retryCount &&
      scriptRetryDelay === script.retryDelay &&
      integrationApiUrl === integration.apiUrl &&
      integrationXToken === integration.xToken &&
      botLocale === localization.locale &&
      useCustomTrainingModel === nlp.useCustomTrainingModel &&
      customTrainingModel === nlp.customTrainingModel &&
      smtpHost === smtp.host &&
      smtpUsername === smtp.username &&
      smtpPassword === smtp.password &&
      smtpName === smtp.name &&
      smtpPort === smtp.port &&
      smtpUseSsl === smtp.useSsl &&
      intentThreshold === recognition.intentThreshold &&
      conversationClosingTimeoutEnabled === conversationClosing.closingEnabled &&
      conversationClosingMessage === conversationClosing.closingMessage &&
      conversationClosingTimeoutMinutesString ===
        secondsToRoundMinutesString(conversationClosing.closingTimeoutSeconds || 0)
    ) {
      return;
    }

    setSaving(true);
    try {
      await botStageApi.updateSettings(botStageId, {
        settings: {
          ...botEdition.structure.settings,
          id: botEdition.structure.settings.id || generateId('SET'),
          $kind: SchemaKind.BotSettings,
          script: {
            ...script,
            content: scriptContent,
            executionTimeout: scriptExecutionTimeout,
            retryCount: scriptRetryCount,
            retryDelay: scriptRetryDelay,
          },
          integrations: [
            {
              ...integration,
              id: integration.id || generateId('EIS'),
              $kind: SchemaKind.Elma365IntegrationSettings,
              apiUrl: integrationApiUrl,
              xToken: integrationXToken,
            } as Elma365IntegrationSettingsSchema,
          ],
          smtp: {
            ...smtp,
            host: smtpHost,
            username: smtpUsername,
            password: smtpPassword,
            name: smtpName,
            port: smtpPort,
            useSsl: smtpUseSsl,
          },
          recognition: {
            ...recognition,
            intentThreshold: intentThreshold,
            nlp: {
              ...nlp,
              useCustomTrainingModel: useCustomTrainingModel,
              customTrainingModel: customTrainingModel,
            },
          },
          conversationClosing: {
            closingEnabled: conversationClosingTimeoutEnabled,
            closingMessage: conversationClosingMessage,
            closingTimeoutSeconds: (parseInt(conversationClosingTimeoutMinutesString) || 0) * 60,
          },
          localization: {
            ...localization,
            locale: botLocale,
          },
        },
      });
      await onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при сохранении настроек бота',
        error: e,
      });
    }
    setSaving(false);
  };

  const onVisiblePropChange = () => {
    if (visible) {
      setCurrentTabPane(TabPaneKeys.GENERAL);
      setScriptContent(script.content || '');
      setScriptExecutionTimeout(script.executionTimeout);
      setScriptRetryCount(script.retryCount);
      setScriptRetryDelay(script.retryDelay);
      setIntegrationApiUrl(integration.apiUrl || '');
      setIntegrationXToken(integration.xToken || '');
      setBotLocale(localization.locale || LOCALE_NAMES.default);
      setUseCustomTrainingModel(nlp.useCustomTrainingModel);
      setCustomTrainingModel(nlp.customTrainingModel ?? undefined);
      setSmtpHost(smtp.host || '');
      setSmtpUsername(smtp.username || '');
      setSmtpPassword(smtp.password || '');
      setSmtpName(smtp.name || '');
      setSmtpPort(smtp.port || SMTP_PORT_DEFAULT);
      setSmtpUseSsl(smtp.useSsl);
      setIntentThreshold(recognition.intentThreshold ?? DEFAULT_INTENT_THRESHOLD);
      setConversationClosingTimeoutEnabled(conversationClosing.closingEnabled);
      setConversationClosingMessage(conversationClosing.closingMessage || '');
      setConversationClosingTimeoutMinutesString(
        secondsToRoundMinutesString(
          conversationClosing.closingTimeoutSeconds
            ? Math.max(conversationClosing.closingTimeoutSeconds, minimumAllowedTimeoutMinutes * 60)
            : CONVERSATION_CLOSING_TIMEOUT_MINUTES_DEFAULT_VALUE * 60
        )
      );
    }
  };
  useEffect(onVisiblePropChange, [visible]);

  const onTabChange = (activeKey: string) => setCurrentTabPane(activeKey as TabPaneKeys);

  const onModalCancel = () => !saving && onClose();

  const onSave = async () => {
    if (saving) return;
    await saveSettings();
    onClose();
  };

  const onScriptContentChange = (value?: string) => setScriptContent(value || '');

  const onScriptExecutionTimeoutChange = (value: string) => setScriptExecutionTimeout(value ? parseInt(value) || 0 : 0);

  const onScriptRetryCountChange = (value: string) => setScriptRetryCount(value ? parseInt(value) || 0 : 0);

  const onScriptRetryDelayChange = (value: string) => setScriptRetryDelay(value ? parseInt(value) || 0 : 0);

  const onIntegrationApiUrlChange = (value: string) => setIntegrationApiUrl(value);

  const onIntegrationXTokenChange = (value: string) => setIntegrationXToken(value);

  const onBotLocaleChange = (value?: SelectValue) => setBotLocale(value as string);

  const onUseCustomTrainingModelClick = () => setUseCustomTrainingModel(!useCustomTrainingModel);

  const onCustomTrainingModelChange = (value?: SelectValue) => setCustomTrainingModel(value as string);

  const onSmtpEndpointChange = (value: string) => setSmtpHost(value);

  const onSmtpUsernameChange = (value: string) => setSmtpUsername(value);

  const onSmtpPasswordChange = (value: string) => setSmtpPassword(value);

  const onSmtpNameChange = (value: string) => setSmtpName(value);

  const onSmtpPortChange = (value: string) => setSmtpPort(value ? parseInt(value) || 0 : 0);

  const onSmtpUseSslChange = () => setSmtpUseSsl(!smtpUseSsl);

  const onIntentThresholdChange = (value: number) => setIntentThreshold(value);

  const onConversationClosingTimeoutEnabledClick = () =>
    setConversationClosingTimeoutEnabled(!conversationClosingTimeoutEnabled);

  const onConversationClosingMessageChange = (value: string) => setConversationClosingMessage(value);

  const onConversationClosingTimeoutMinutesStringBlur = () => {
    let numberValue = parseInt(conversationClosingTimeoutMinutesString) || 0;
    numberValue = Math.max(minimumAllowedTimeoutMinutes, numberValue);
    setConversationClosingTimeoutMinutesString(numberValue.toString());
  };

  const onShowAgentStageIdCopiedChange = () => {
    if (showAgentStageIdCopied) {
      !!showAgentStageIdCopiedTimer && clearTimeout(showAgentStageIdCopiedTimer);
      setShowAgentStageIdCopiedTimer(
        setTimeout(() => setShowAgentStageIdCopied(false), SHOW_AGENT_STAGE_ID_COPIED_TIMEOUT)
      );
    }
  };
  useEffect(onShowAgentStageIdCopiedChange, [showAgentStageIdCopied]);

  const onCopyAgentStageIdClick = () => {
    if (!agentStageId) return;
    navigator.clipboard.writeText(agentStageId);
    setShowAgentStageIdCopied(true);
  };

  const onShowXTokenCopiedChange = () => {
    if (showXTokenCopied) {
      !!showXTokenCopiedTimer && clearTimeout(showXTokenCopiedTimer);
      setShowXTokenCopiedTimer(setTimeout(() => setShowXTokenCopied(false), SHOW_XTOKEN_COPIED_TIMEOUT));
    }
  };
  useEffect(onShowXTokenCopiedChange, [showXTokenCopied]);

  const onCopyXTokenClick = () => {
    if (!tenantXToken) return;
    navigator.clipboard.writeText(tenantXToken);
    setShowXTokenCopied(true);
  };

  const onConversationClosingTimeoutMinutesPressEnter = (e: KeyboardEvent<HTMLInputElement>) => e.currentTarget?.blur();

  const onDevDocsLinkButtonClick = async () => {
    const config = await getConfig();
    const basePath = config?.devDocs?.basePath;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(basePath, '_blank');
  };

  const renderCopyAgentStageIdButton = () =>
    !showAgentStageIdCopied ? (
      <SbButton icon={<SbIcon iconName="copy" size={20} />} sbType="link" onClick={onCopyAgentStageIdClick} />
    ) : (
      <SbButton icon={<SbIcon iconName="check-small" size={20} />} sbType="link" />
    );

  const renderCopyXTokenButton = () =>
    !showXTokenCopied ? (
      <SbButton icon={<SbIcon iconName="copy" size={20} />} sbType="link" onClick={onCopyXTokenClick} />
    ) : (
      <SbButton icon={<SbIcon iconName="check-small" size={20} />} sbType="link" />
    );

  return (
    <SbModal
      destroyOnClose
      className="sb-bot-settings-modal"
      footer={[
        <SbButton
          key="save"
          disabled={saving}
          icon={saving ? <SbIcon spin iconName="loading-four" size={16} /> : null}
          sbSize="medium"
          sbType="icon-primary"
          onClick={onSave}
        >
          Сохранить
        </SbButton>,
        <SbButton key="cancel" disabled={saving} sbSize="medium" sbType="secondary" onClick={onModalCancel}>
          Отмена
        </SbButton>,
      ]}
      keyboard={false}
      maskClosable={false}
      sbSize="small"
      title="Настройки бота"
      visible={visible}
      width="95%"
      onCancel={onModalCancel}
      onOk={onSave}
    >
      <SbTabs activeKey={currentTabPane} onChange={onTabChange}>
        <SbTabPane key={TabPaneKeys.GENERAL} tab="Основное">
          <Row className="sb-bot-settings-modal__general" gutter={[48, 20]} wrap={false}>
            <Col span={10}>
              <div className="sb-bot-settings-modal__general__main-area">
                <SbTypography>
                  <h2>Язык бота</h2>
                  <SbSelect
                    options={BotLanguages}
                    sbSize="big"
                    sbType="light"
                    value={botLocale || LOCALE_NAMES.russian}
                    onChange={onBotLocaleChange}
                  ></SbSelect>
                  <Flags authorizedFlags={[FeatureFlagNames.TRAINING_MODEL_SELECTION]}>
                    <h2>Модель обучения</h2>
                    <SbSwitch
                      checked={useCustomTrainingModel}
                      label="Использовать указанную модель обучения бота"
                      onClick={onUseCustomTrainingModelClick}
                    />
                    <SbSelect
                      disabled={!useCustomTrainingModel}
                      options={trainingModelsOptions}
                      sbSize="big"
                      sbType="light"
                      value={customTrainingModel}
                      onChange={onCustomTrainingModelChange}
                    />
                  </Flags>
                </SbTypography>
              </div>
            </Col>
            <Col span={14}>
              <SbPanel scrollable sbType="help">
                <SbTypography>
                  <h3>Язык бота</h3>
                  <p>
                    Эта настройка определяет, на каком языке будет вести общение бот. Она влияет на следующие аспекты
                    работы бота:
                  </p>
                  <ul>
                    <li>
                      Какая модель используется при обучении бота (бот будет обучаться при помощи наиболее подходщей для
                      выбранного языка модели)
                    </li>
                    <li>
                      Какой язык и формат данных используются в распознавателях при обработке ввода пользователя (ввод
                      даты, ФИО, местоположения и т.п.)
                    </li>
                    <li>
                      На каком языке отображаются фиксированные фразы бота и надписи на кнопках (например, сообщение о
                      подтверждении загрузки файлов, кнопки &quot;Назад&quot; и &quot;Отмена&quot;, сообщения об ошибках
                      и т.д.)
                    </li>
                  </ul>
                  <Flags authorizedFlags={[FeatureFlagNames.TRAINING_MODEL_SELECTION]}>
                    <h3>Модель обучения</h3>
                    <p>
                      Эта настройка позволяет выбрать, какая модель будет использована при обучении бота. Доступны
                      следующие варианты:
                    </p>
                    <ul>
                      {trainingModelsOptions.map((m) => (
                        <li key={m.value}>
                          <strong>{m.label}</strong>
                          <br />
                          {m.description}
                        </li>
                      ))}
                    </ul>
                  </Flags>
                </SbTypography>
              </SbPanel>
            </Col>
          </Row>
        </SbTabPane>
        <SbTabPane key={TabPaneKeys.INTEGRATIONS} tab="Интеграции">
          <div className="sb-bot-settings-modal__integrations">
            <SbTypography>
              <h2>ELMA365:</h2>
              <h3>
                <span>Адрес API</span>
                <SbTooltip
                  overlayClassName="sb-bot-settings-modal__integrations__tooltip"
                  placement="right"
                  title={
                    <div>
                      Адрес компании ELMA 365
                      <br />
                      https://&#123;company&#125;.elma365.&#123;zone&#125;
                      <br />
                      пример <b>https://xskgndm.elma365.ru</b>
                    </div>
                  }
                >
                  <SbIcon iconName="help" size={16} />
                </SbTooltip>
              </h3>
              <SbInput sbSize="big" value={integrationApiUrl} onChange={onIntegrationApiUrlChange} />
              <h3>X-Token</h3>
              <SbInput sbSize="big" value={integrationXToken} onChange={onIntegrationXTokenChange} />
              <Elma365IntegrationCheckButton key="check" apiUrl={integrationApiUrl} xToken={integrationXToken} />
            </SbTypography>
          </div>
        </SbTabPane>
        <SbTabPane key={TabPaneKeys.SCRIPT} forceRender tab="Скрипт">
          <div className="sb-bot-settings-modal__script-container">
            <div className="sb-bot-settings-modal__script-container__editor">
              <SbScriptEditor
                height="100%"
                schema={botEdition.structure}
                value={scriptContent}
                onChange={onScriptContentChange}
              />
            </div>
            <SbScroll>
              <SbTypography>
                <h2>Обработка ошибок скрипта</h2>
                <h3>Максимальное время выполнения скрипта в секундах</h3>
                <SbInput
                  sbSize="big"
                  value={scriptExecutionTimeout?.toString()}
                  onChange={onScriptExecutionTimeoutChange}
                />
                <h3>Количество повторных попыток выполнения скрипта</h3>
                <SbInput sbSize="big" value={scriptRetryCount.toString()} onChange={onScriptRetryCountChange} />
                <h3>Задержка времени в секундах между повторными попытками</h3>
                <SbInput sbSize="big" value={scriptRetryDelay.toString()} onChange={onScriptRetryDelayChange} />
                <SbButton sbType="link" onClick={onDevDocsLinkButtonClick}>
                  Документация разработчика
                </SbButton>
              </SbTypography>
            </SbScroll>
          </div>
        </SbTabPane>
        <SbTabPane key={TabPaneKeys.SMTP} tab="Настройки SMTP">
          <div className="sb-bot-settings-modal__smtp-settings">
            <SbTypography>
              <h3>Адрес сервера</h3>
              <SbInput allowClear sbSize="big" value={smtpHost} onChange={onSmtpEndpointChange} />
              <h3>Имя пользователя</h3>
              <SbInput allowClear sbSize="big" value={smtpUsername} onChange={onSmtpUsernameChange} />
              <h3>Пароль</h3>
              <SbInput.Password allowClear sbSize="big" value={smtpPassword} onChange={onSmtpPasswordChange} />
              <h3>Имя отправителя</h3>
              <SbInput allowClear sbSize="big" value={smtpName} onChange={onSmtpNameChange} />
              <h3>Порт сервера</h3>
              <SbInput allowClear sbSize="big" value={smtpPort.toString()} onChange={onSmtpPortChange} />
              <SbSwitch checked={smtpUseSsl} label={'Использовать SSL'} onChange={onSmtpUseSslChange} />
            </SbTypography>
          </div>
        </SbTabPane>
        <SbTabPane key={TabPaneKeys.RECOGNITION} tab="Распознавание">
          <div className="sb-bot-settings-modal__recognition-settings">
            <SbTypography>
              <h3>Порог уверенности распознавания</h3>
              <small className="sb-bot-settings-modal__recognition-settings__small-text">
                Минимально необходимое значение уверенности в том, что фраза похожа на искомую.
                <br />
                Если значение будет меньше порогового, то ответ не будет показан пользователю.
                <br />
                Возможные значения от 0 до 1, не рекомендуется устанавливать значение менее 0,6.
                <br />
                Чем больше значение, тем выше точность распознавания.
              </small>
            </SbTypography>
            <div className="sb-bot-settings-modal__recognition-settings__slider">
              <span>Низкий</span>
              <SbSlider
                max={INTENT_THRESHOLD_MAX_VALUE}
                min={INTENT_THRESHOLD_MIN_VALUE}
                step={INTENT_THRESHOLD_STEP}
                value={intentThreshold}
                onChange={onIntentThresholdChange}
              />
              <span>Высокий</span>
            </div>
          </div>
        </SbTabPane>
        <SbTabPane key={TabPaneKeys.API} tab="API">
          <div className="sb-bot-settings-modal__api-settings">
            <SbTypography>
              <h3>
                <span>X-Token</span>
                <SbTooltip placement="right" title="Необходим для авторизации запросов к API">
                  <SbIcon iconName="help" size={16} />
                </SbTooltip>
              </h3>
              <div className="sb-bot-settings-modal__api-settings__field">
                <SbInput disabled allowClear={false} value={tenantXToken} />
                <SbTooltip placement="right" title={!showXTokenCopied ? 'Скопировать X-Token' : 'X-Token скопирован!'}>
                  {renderCopyXTokenButton()}
                </SbTooltip>
              </div>
              <h3>Идентификатор бота</h3>
              <div className="sb-bot-settings-modal__api-settings__field">
                <SbInput disabled allowClear={false} value={agentStageId} />
                <SbTooltip
                  placement="right"
                  title={!showAgentStageIdCopied ? 'Скопировать идентификатор бота' : 'Идентификатор бота скопирован!'}
                >
                  {renderCopyAgentStageIdButton()}
                </SbTooltip>
              </div>
            </SbTypography>
          </div>
        </SbTabPane>
        <SbTabPane key={TabPaneKeys.ADDITIONAL} tab="Дополнительные">
          <div className="sb-bot-settings-modal__additional">
            <SbTypography>
              <Flags authorizedFlags={[FeatureFlagNames.CONVERSATION_CLOSING]}>
                <h2>Автоматическое завершение бесед</h2>
                <SbSwitch
                  checked={conversationClosingTimeoutEnabled}
                  label="Включить автоматическое завершение"
                  onClick={onConversationClosingTimeoutEnabledClick}
                />
                <Collapse
                  ghost
                  activeKey={conversationClosingTimeoutEnabled ? '1' : '0'}
                  bordered={false}
                  expandIcon={() => null}
                >
                  <Panel key="1" header="">
                    <SbTypography>
                      <h3>Таймаут в минутах</h3>
                      <SbInput
                        sbSize="big"
                        value={conversationClosingTimeoutMinutesString}
                        onBlur={onConversationClosingTimeoutMinutesStringBlur}
                        onChange={setConversationClosingTimeoutMinutesString}
                        onPressEnter={onConversationClosingTimeoutMinutesPressEnter}
                      />
                      <h3>Фраза завершения беседы</h3>
                      <SbInput
                        sbSize="big"
                        value={conversationClosingMessage}
                        onChange={onConversationClosingMessageChange}
                      />
                    </SbTypography>
                  </Panel>
                </Collapse>
              </Flags>
            </SbTypography>
          </div>
        </SbTabPane>
      </SbTabs>
    </SbModal>
  );
};

export default SettingsModal;
