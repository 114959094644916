import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/lib/upload';

import './index.less';

import IbButton from '../common/IbButton';
import IbTypography from '../common/IbTypography';
import IbIcon, { IbIconName } from '../common/IbIcon';
import IbCard from '../common/IbCard';
import IbSwitch from '../common/IbSwitch';
import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbDivider from '../common/IbDivider';
import IbBadge from '../common/IbBadge';
import { formatDateTimeAdaptive } from '../../../utils/stringUtil';
import { ScenarioCancellationMode, ScenarioInterruptionMode } from '../../../../api';
import SbScenarioModeTag from '../../../simple-bot/components/SbScenarioModeTag';
import SbUpload from '../../../simple-bot/components/common/SbUpload';
import { ALLOWED_IMPORT_SCENARIO_FILE_TYPES } from '../../../constants';
import IbContextMenu, { IIbContextMenuItem } from '../common/IbContextMenu';
import IbTextHighlighter from '../common/IbTextHighlighter';

const MAX_TRIGGERS_VISIBLE = 6;

const MAIN_CLASS_NAME = 'ib-scenario-card';
const EXTRA_CLASS_NAME = `${MAIN_CLASS_NAME}__extra`;
const TRIGGERS_GRID_CLASS_NAME = `${MAIN_CLASS_NAME}__triggers`;
const TRIGGERS_GRID_FOUR_ITEMS_CLASS_NAME = `${TRIGGERS_GRID_CLASS_NAME}_four-items`;
const TRIGGER_CLASS_NAME = `${TRIGGERS_GRID_CLASS_NAME}__trigger`;
const SHOW_MORE_TRIGGERS_CLASS_NAME = `${TRIGGERS_GRID_CLASS_NAME}__show-more`;
const FOOTER_CLASS_NAME = `${MAIN_CLASS_NAME}__footer`;

export interface IScenarioTrigger {
  name: string;
  iconName: string;
}

export interface IIbScenarioCardProps {
  enabled: boolean;
  toggling: boolean;
  scenarioName: string;
  searchText: string;
  cancellationMode: ScenarioCancellationMode;
  interruptionMode: ScenarioInterruptionMode;
  triggers: IScenarioTrigger[];
  modifiedOn: string;
  title: string;
  titleIsEditing?: boolean;
  onScenarioClick: () => void;
  onToggleScenario: () => void;
  onTitleInputBlur?: () => void;
  onTitleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTitleInputFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onTitleInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onDuplicateScenario?: () => void;
  onRenameScenario?: () => void;
  onExportScenario?: () => void;
  onImportScenarioFileUpload?: (file: RcFile, base64Content: string) => void;
  onChangeScenarioSettings?: () => void;
  onDeleteScenario?: () => void;
}

const IbScenarioCard: React.FC<IIbScenarioCardProps> = ({
  enabled,
  toggling,
  scenarioName,
  searchText,
  cancellationMode,
  interruptionMode,
  triggers,
  modifiedOn,
  title,
  titleIsEditing,
  onScenarioClick,
  onToggleScenario,
  onTitleInputBlur,
  onTitleInputChange,
  onTitleInputFocus,
  onTitleInputKeyDown,
  onDuplicateScenario,
  onRenameScenario,
  onExportScenario,
  onImportScenarioFileUpload,
  onChangeScenarioSettings,
  onDeleteScenario,
}) => {
  const { t, i18n } = useTranslation();
  triggers = getDefaultIfUndefined(triggers, []);

  const [contextMenuVisible, setContextMenuVisible] = useState(false);

  const showMoreTriggersVisible = triggers.length > MAX_TRIGGERS_VISIBLE;

  const uploadMenuItemContent = (
    <SbUpload
      accept={ALLOWED_IMPORT_SCENARIO_FILE_TYPES.join(',')}
      onFileUpload={(file, base64Content) => {
        onImportScenarioFileUpload?.(file, base64Content);
      }}
    >
      {t('Import')}
    </SbUpload>
  );

  const contextMenuItems = [
    {
      icon: '',
      onSelect: onScenarioClick,
      text: t('Open'),
    },
    {
      icon: '',
      onSelect: onToggleScenario,
      text: enabled ? t('Disable') : t('Enable'),
    },
    {
      icon: '',
      onSelect: onDuplicateScenario,
      text: t('Duplicate'),
    },
    {
      icon: '',
      onSelect: onRenameScenario,
      text: t('Rename'),
    },
    {
      icon: '',
      onSelect: onExportScenario,
      text: t('Export'),
    },
    {
      icon: '',
      text: uploadMenuItemContent,
    },
    {
      icon: '',
      onSelect: onChangeScenarioSettings,
      text: t('Settings'),
    },
    {
      icon: '',
      onSelect: onDeleteScenario,
      text: t('Delete'),
    },
  ] as IIbContextMenuItem[];

  const renderExtra = () => {
    const onContextMenuClose = () => setContextMenuVisible(false);
    const onMenuButtonClick = () => setContextMenuVisible(true);
    return (
      <div
        className={EXTRA_CLASS_NAME}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IbSwitch checked={enabled} loading={toggling} onChange={onToggleScenario} />
        <IbContextMenu menuItems={contextMenuItems} visible={contextMenuVisible} onClose={onContextMenuClose}>
          <IbButton icon={<IbIcon iconName="more-one" size={24} />} type="icon" onClick={onMenuButtonClick} />
        </IbContextMenu>
      </div>
    );
  };

  const renderTrigger = (trigger: IScenarioTrigger) => {
    return (
      <div className={TRIGGER_CLASS_NAME} title={trigger.name}>
        <IbIcon iconName={trigger.iconName as IbIconName} size={20} />
        <IbTypography.Paragraph type="secondary">
          <IbTextHighlighter highlightedText={searchText} text={trigger.name} />
        </IbTypography.Paragraph>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className={FOOTER_CLASS_NAME}>
        <IbTypography.Paragraph disabled>{t('TriggerWithCount', { count: triggers.length })}</IbTypography.Paragraph>
        <IbDivider orientation="center" type="vertical" />
        <IbTypography.Paragraph disabled>
          <span>{t('Modified')}</span> <span>{formatDateTimeAdaptive(modifiedOn, i18n.language, 'short', false)}</span>
        </IbTypography.Paragraph>
        <SbScenarioModeTag cancellation={cancellationMode} interruption={interruptionMode} />
      </div>
    );
  };

  const maxTriggersVisibleCount = showMoreTriggersVisible ? MAX_TRIGGERS_VISIBLE - 1 : triggers.length;

  const renderShowMoreTriggers = () => {
    return (
      <div className={[TRIGGER_CLASS_NAME, SHOW_MORE_TRIGGERS_CLASS_NAME].join(' ')} onClick={onScenarioClick}>
        <IbBadge value={`+${triggers.length - maxTriggersVisibleCount}`} />
        <IbButton type="link">{t('Show all')}</IbButton>
      </div>
    );
  };

  const triggersGridClasses = [TRIGGERS_GRID_CLASS_NAME];
  triggers.length === 4 && triggersGridClasses.push(TRIGGERS_GRID_FOUR_ITEMS_CLASS_NAME);

  return (
    <div className={MAIN_CLASS_NAME}>
      <IbCard
        editingTitle={title}
        extra={renderExtra()}
        footer={renderFooter()}
        searchText={searchText}
        title={scenarioName}
        titleIsEditing={titleIsEditing}
        onClick={onScenarioClick}
        onTitleInputBlur={onTitleInputBlur}
        onTitleInputChange={onTitleInputChange}
        onTitleInputFocus={onTitleInputFocus}
        onTitleInputKeyDown={onTitleInputKeyDown}
      >
        <div className={triggersGridClasses.join(' ')}>
          {triggers.slice(0, maxTriggersVisibleCount).map(renderTrigger)}
          {showMoreTriggersVisible && renderShowMoreTriggers()}
        </div>
      </IbCard>
    </div>
  );
};

export default IbScenarioCard;
